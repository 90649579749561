import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AppLayout from "./AppLayout";
import PasswordFactory from "../resources/password";
import sweetalert2 from "sweetalert2";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import * as rh from "./helpers/routes";
import { I18nRails } from "../../shared/rails-i18n-js";
import { loadImage } from "./helpers/utils";
import withParent from "./hoc/withParent";

const AppResetPassword = ({
  Rails,
  notifyNative,
  showResourceError,
  user,
  ...others
}) => {

  const [values, setValues] = useState({
    email: "",
  });

  const [error, setError] = useState();

  const navigate = useNavigate()
  useEffect(() => {
    notifyNative("onForgotPassword");
  });

  const onSubmit = (values, options) => {
    const ResetPassword = PasswordFactory.get(Rails);
    return ResetPassword.create(
      { user: values },
      (success) => {
        options.setSubmitting(false);
        sweetalert2.fire(
          success.message ||
          "An email has been sent to you with reset password link"
        );
        navigate("/login", { reset: true });
      },
      (error) => {
        options.setSubmitting(false);
        showResourceError(error);
      }
    );
  };

  const validateHandler = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = [I18nRails.t("client.profile.login.email_required")];
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = [
        I18nRails.t("client.profile.login.invalid_email_address"),
      ];
    }

    return errors;
  };

  const children = ({ values, errors, touched, isSubmitting, isValidating }) => {
    return (
      (<Form method="post" className="form-signin login-form">
        <Grid container justify="center" alignItems="center">
          <Grid size={10}>
            <Typography gutterBottom variant="p" component="p">
              {I18nRails.t("client.profile.login.forgot_pass_paragraph")}
            </Typography>
            <Field
              className="form-control browser-default"
              type="email"
              name="email"
              placeholder={I18nRails.t("Web.email")}
            />
            <ErrorMessage name="email" component="validation-message" />
            {error && <span>{error}</span>}
          </Grid>
          <Grid size={5}>
            <div className="input-field login-btn">
              <Button
                className="btn btn-block btn-login btn-first waves-effect waves-light"
                style={{marginTop: '8%'}}
                type="submit"
                disabled={isSubmitting}
              >
                {I18nRails.t("client.profile.login.reset_password")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>)
    );
  };

  return (
    (<AppLayout Rails={Rails} user={user} {...others}>
      <Grid
        style={{ height: '95vh', marginTop: '5%', backgroundImage: 'url(' + loadImage('image.jpg') + ')' }}
        size={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid
            size={{
              xs: 10,
              sm: 10,
              md: 4,
              lg: 4
            }}>
            <h3 style={{marginTop:'2%'}} className="center white-text">
              {I18nRails.t("client.profile.login.forgot_password")}
            </h3>
            <Card elevation={7} style={{marginBottom: '1%', height: 'auto', padding: '25px'}}>
              <Formik
                className="form-signin login-form mg-btm"
                initialValues={values}
                validate={validateHandler}
                onSubmit={onSubmit}
              >
                {children}
              </Formik>
            </Card>
          </Grid>
          <Grid size={5}>
            <div className="input-field">
              <p style={{textAlign: 'center'}} className="margin medium-small">
                <Link to={rh.login()} className="white-text">
                  {I18nRails.t("client.profile.login.back_to_login")}
                </Link>
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>)
  );
};


export default withParent(AppResetPassword);
