import React from "react";
import Zoom from "react-medium-image-zoom";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import { I18nRails } from "../../../shared/rails-i18n-js";

import "react-medium-image-zoom/dist/styles.css";
import LocationResource from "../../resources/location";
import { getLocationTime, locationTimeToday, location_address, location_header, location_logo_url, location_main_image_url } from "../../models/location";

const template = ({
  fndLocation,
  openHours,
  isMenuMode
}) => {
  let fdLocation;
  if(fndLocation) {
    fdLocation = LocationResource.attachMethods(fndLocation);
  }
  const backgroundImg = location_main_image_url(fdLocation)
  return (<>
    {fndLocation && (
      <>
        <Grid container className="half-bg-image" sx={{
          backgroundImage: 'linear-gradient(rgba(15, 22, 39, .7),rgba(15, 22, 39, .7)), url('+backgroundImg+')',
          backgroundSize: {xs: "cover"},
          marginTop: !isMenuMode ? { xs: "80px", md: "155px" } : 0,
          minHeight: { md: "90vh"}
        }}>
          <Grid size={3}>
            {fndLocation.logo_url && (
              <span id="heading_logo">
                <img
                  alt={fndLocation.name}
                  src={location_logo_url(fndLocation)}
                  style={{ height: "50%", borderRadius: "9px" , width:'85%' , objectFit:'cover' }}
                />
              </span>
            )}
          </Grid>
          <Grid size={6}>
            <div className="left-align">
              <h3 className="white-text">{fndLocation.name}</h3>
              {fndLocation.addresses && (
                <p className="white-text">
                  {location_address(fndLocation).full_address}
                </p>
              )}
              {locationTimeToday(fndLocation) && (
                <p className="white-text">{getLocationTime(fndLocation)}</p>
              )}
              {fndLocation.min_delivery_price && (
                <p className="white-text">
                  {I18nRails.t("Web.min_order")}: {fndLocation.min_delivery_amount}
                </p>
              )}
              {location_header(fndLocation) && (
                <p className="white-text">{"Note: " + location_header(fndLocation)}</p>
              )}
              <br />
            </div>
          </Grid>
          <Grid size={3}>
            {fndLocation.vouchers.length > 0 && (
              <div
                style={{
                  paddingLeft: "6%",
                  paddingTop: "10%",
                  paddingBottom: "10%",
                  color: "white",
                  height: "auto",
                  marginTop: "15%",
                  width: "29vh",
                  backgroundColor: "#e53935",
                  borderRadius: "5px"
                }}
              >
                {fndLocation.vouchers[
                  fndLocation.vouchers.length - 1
                ].discount_type == "percentage" ? (
                  <span style={{ textShadow: "none" }}>
                    {fndLocation.vouchers[
                      fndLocation.vouchers.length - 1
                    ].discount}
                    % OFF!
                  </span>
                ) : (
                  <span style={{ textShadow: "none" }}>
                    {fndLocation.currency}.{" "}
                    {fndLocation.vouchers[
                      fndLocation.vouchers.length - 1
                    ].discount}
                    OFF!
                  </span>
                )}
                <br />
                <span style={{ textShadow: "none" }}>
                  Use Promo:{" "}
                  {fndLocation.vouchers[fndLocation.vouchers.length - 1].code}
                </span>
              </div>
            )}
            <Button
              onClick={(e) => openHours()}
              sx={{ color: "white", backgroundColor: "#0f1626"}}
            >
              {I18nRails.t("Web.view_maps_hours")}
            </Button>
          </Grid>
        </Grid>
      </>
    )}
  </>);  
};

export default template;
