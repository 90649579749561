import React, { useContext, createContext } from 'react';

const ssrPropsContext = createContext({});

export const useSsrProps = () => {
  return useContext(ssrPropsContext);
}

export const SsrPropsProvider = ({
  children,
  props,
}) => {
  return (
    <ssrPropsContext.Provider value={props}>
      {children}
    </ssrPropsContext.Provider>
  );
}
