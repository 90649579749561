import React from "react";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { PayPalButton } from "react-paypal-button-v2";
import { spacing } from "@mui/system";
import { I18nRails } from "../../../shared/rails-i18n-js";
import ShowCart from "./ShowCart";

const template = ({
  basket,
  channel,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  clearCart,
  proceedToCheckout,
  order,
  radioValue,
  orderProcessing,
  paypalProps,
  placeOrder,
}) => {
  return (<>
    <ShowCart
      hideCheckout={true}
      basket={basket}
      channel={channel}
      setBasketField={setBasketField}
      verifyVoucherCode={verifyVoucherCode}
      changeQuantity={changeQuantity}
      clearCart={clearCart}
      proceedToCheckout={proceedToCheckout}
      order={order}
    />
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 5,
          md: 10
        }} />
      <Grid
        size={{
          xs: 7,
          md: 2
        }}>
        {radioValue.admin_name === "paypal" &&
        basket.totals.numberOfCartItems > 0 ? (
          <PayPalButton disabled={orderProcessing} {...paypalProps} />
        ) : radioValue.admin_name !== "paypal" &&
          basket.totals.numberOfCartItems > 0 ? (
          <Button
            className="payment-button"
            type="submit"
            variant="contained"
            style={{
              width: "83%",
              marginTop: "3%",
              paddingTop: "4%",
              paddingBottom: "4%",
              marginLeft: "19%",
            }}
            color="primary"
            onClick={(e) => placeOrder()}
          >
            <i className="fas fa-file-invoice-dollar white-text"></i>
            <label className="white-text">
              {I18nRails.t("Web.confirm_order")}
            </label>
          </Button>
        ) : null}
      </Grid>
    </Grid>
  </>);
};

export default template;
