import React from "react";

import { Link } from "react-router";
import { I18nRails } from "../../../shared/rails-i18n-js";
import { Box, Typography } from "@mui/material";

const template = (message, loc) => {
  return (
    <Box sx={{ height: "100px"}}>
      <Typography>{message}</Typography>
      <Box sx={{ display: "flex" }}>
        <Box>
          <a
            href={'/join?return_url='+loc.pathname+loc.search}
            className="btnLogin btn-second"
            id="register_btn"
          >
            {I18nRails.t("client.profile.login.new_to_foodnerd")}
            <span className="largeText">
              &nbsp; {I18nRails.t("client.profile.login.join")}&nbsp;
            </span>
            <br />
          </a>
        </Box>
        <Box>
          <a
            href={'/login?return_url='+loc.pathname+loc.search}
            className="btnLogin btn-first"
            id="register_btn"
          >
            {I18nRails.t("client.profile.login.new_to_foodnerd")}
            <span className="largeText">
              &nbsp; {I18nRails.t("client.profile.login.join")}&nbsp;
            </span>
            <br />
          </a>
        </Box>
      </Box>
    </Box>
  );
};
// pug `
//   p= message
//   .row
//     .col.m3.s12
//       div
//         Link(to='/join?return_url='+loc.pathname+loc.search)
//           button.btnLoginCart.btn-second
//             | New to Foodnerd?
//             div.largeText Join
//             br
//     .col.m3.s12
//       div
//         Link(to='/login?return_url='+loc.pathname+loc.search)
//           button.btnLoginCart.btn-first
//             | Already a Foodnerd user ?
//             div.largeText Login
//             br
// `;

export default template;
