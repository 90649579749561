import React from 'react';
import { NavLink, useLocation } from "react-router";
import { showCount } from '../../filters';
import { I18nRails } from '../../../shared/rails-i18n-js';
import * as rh from '../helpers/routes';

// const template = (props) => pug`
//   // ul(style={listStyleType: 'none'} id="locationList").tabs
//     li.tab.col.left-menu(className=location.pathname == rh.show_location({location_id: fndLocation.slug}, Rails) ? 'active' : '')
//       NavLink(to=rh.show_location({location_id: fndLocation.slug}, Rails), exact)
//         i.material-icons.menu-like restaurant
//         | ${ I18nRails.t('Web.menu.singular') }
//     li.tab.col.left-menu(className=location.pathname == rh.location_about({location_id: fndLocation.slug}, Rails) ? 'active' : '')
//       NavLink(to=rh.location_about({location_id: fndLocation.slug}, Rails))
//         i.material-icons.menu-like info
//         |  ${ I18nRails.t('Web.about') } 
//     li.tab.col.left-menu(className=location.pathname == rh.location_order_history({location_id: fndLocation.slug}, Rails) ? 'active' : '')
//       NavLink(to=rh.location_order_history({location_id: fndLocation.slug}, Rails))
//         i.material-icons.menu-like history
//         |  ${ I18nRails.t('Web.orders') } 
//     li.tab.col.left-menu(className=location.pathname == rh.location_blogs({location_id: fndLocation.slug}, Rails) ? 'active' : '')
//       NavLink(to=rh.location_blogs({location_id: fndLocation.slug}, Rails))
//         i.material-icons.menu-like forum
//         = showCount(I18nRails.t('Web.blogs'), fndLocation.blogs_count)
//     li.tab.col.left-menu(className=location.pathname == rh.location_reviews({location_id: fndLocation.slug}, Rails) ? 'active' : '')
//       NavLink(to=rh.location_reviews({location_id: fndLocation.slug}, Rails))
//         i.material-icons.menu-like rate_review
//         = showCount(I18nRails.t('Web.reviews'), fndLocation.reviews_count)
// `;
const template = ({
  Rails,
  fndLocation
}) => {
  const domLocation = useLocation();
  <ul style={{ listStyleType: 'none' }} id="locationList" className="tabs">
    <li className={`tab col left-menu ${domLocation.pathname === rh.show_location({ location_id: fndLocation.slug }, Rails) ? 'active' : ''}`}>
      <NavLink to={rh.show_location({ location_id: fndLocation.slug }, Rails)} exact>
        <i className="material-icons menu-like">restaurant</i>
        {I18nRails.t('Web.menu.singular')}
      </NavLink>
    </li>
    <li className={`tab col left-menu ${domLocation.pathname === rh.location_about({ location_id: fndLocation.slug }, Rails) ? 'active' : ''}`}>
      <NavLink to={rh.location_about({ location_id: fndLocation.slug }, Rails)}>
        <i className="material-icons menu-like">info</i>
        {I18nRails.t('Web.about')}
      </NavLink>
    </li>
    <li className={`tab col left-menu ${domLocation.pathname === rh.location_order_history({ location_id: fndLocation.slug }, Rails) ? 'active' : ''}`}>
      <NavLink to={rh.location_order_history({ location_id: fndLocation.slug }, Rails)}>
        <i className="material-icons menu-like">history</i>
        {I18nRails.t('Web.orders')}
      </NavLink>
    </li>
    <li className={`tab col left-menu ${domLocation.pathname === rh.location_blogs({ location_id: fndLocation.slug }, Rails) ? 'active' : ''}`}>
      <NavLink to={rh.location_blogs({ location_id: fndLocation.slug }, Rails)}>
        <i className="material-icons menu-like">forum</i>
        {showCount(I18nRails.t('Web.blogs'), fndLocation.blogs_count)}
      </NavLink>
    </li>
    <li className={`tab col left-menu ${domLocation.pathname === rh.location_reviews({ location_id: fndLocation.slug }, Rails) ? 'active' : ''}`}>
      <NavLink to={rh.location_reviews({ location_id: fndLocation.slug }, Rails)}>
        <i className="material-icons menu-like">rate_review</i>
        {showCount(I18nRails.t('Web.reviews'), fndLocation.reviews_count)}
      </NavLink>
    </li>
  </ul>
};


export default template;
