import React from 'react';
import { Link } from "react-router";
import * as rh from '../helpers/routes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import { I18nRails } from '../../../shared/rails-i18n-js';
import { loadImage } from '../helpers/utils';
import { useSsrProps } from '../../SsrContext';
import { Paper } from '@mui/material';

const AppFooter = ({
  fndLocation,
  basket,
  numberOfCartItems,
  countryConfig
}) => {

  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  return (<>
    <Paper sx={{display: { md: 'block', lg: 'none' }}}>
      {!basket || !fndLocation ? (
        <div className="bottom-nav" id="bottombar">
          <Link to="/locations" className="bottom-nav__action">
            <i className="material-icons">restaurant_menu</i>
            <span className="bottom-nav__label">{I18nRails.t("footer.locations")}</span>
          </Link>
          <Link to="/favorites" className="bottom-nav__action">
            <i className="material-icons">favorite</i>
            <span className="bottom-nav__label">Favorites</span>
          </Link>
          <Link to="/orders" className="bottom-nav__action">
            <i className="material-icons">list</i>
            <span className="bottom-nav__label">Recent Orders</span>
          </Link>
          <Link to="/cart" className="bottom-nav__action">
            <i className={`fas fa-shopping-cart ${numberOfCartItems > 0 ? 'badge1' : ''}`} data-badge={numberOfCartItems} />
            <span className="bottom-nav__label">Cart</span>
          </Link>
        </div>
      ) : (
        <div className="bottom-nav" id="bottombar">
          <Link to="/locations" className="bottom-nav__action">
            <i className="material-icons">restaurant_menu</i>
            <span className="bottom-nav__label">Locations</span>
          </Link>
          <Link to={rh.show_location({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
            <i className="material-icons">location_on</i>
            <span className="bottom-nav__label">Menu</span>
          </Link>
          {!!fndLocation && fndLocation.pages && fndLocation.pages.map(page => (
            <Link to={rh.location_page({location_id: fndLocation.slug, page_id: page.slug}, Rails)} key={`hol-${page.id}`}>
              <span className="bottom-nav__label">{page.title}</span>
            </Link>
          ))}
          {!!fndLocation && fndLocation.pages && (
            <>
              <Link to={rh.location_blogs({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
                <i className={`material-icons ${fndLocation.blogs_count > 0 ? 'badge1' : ''}`} data-badge={fndLocation.blogs_count}>rss_feed</i>
                <span className="bottom-nav__label">{I18nRails.t("business_dashboard.footer.blog")}</span>
              </Link>
              <Link to={rh.location_reviews({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
                <i className={`material-icons ${fndLocation.reviews_count > 0 ? 'badge1' : ''}`} data-badge={fndLocation.reviews_count}>rate_review</i>
                <span className="bottom-nav__label">Reviews</span>
              </Link>
            </>
          )}
          <Link to={rh.location_cart({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
            <i className={`fas fa-shopping-cart ${basket.totals.numberOfCartItems > 0 ? 'badge1' : ''}`} data-badge={basket.totals.numberOfCartItems}></i>
            <span className="bottom-nav__label">Cart</span>
          </Link>
        </div>
      )}        
    </Paper>
    <Paper sx={{display: { xs: 'none', sm: 'block' }}}>
      <div className="footer hide-on-small-only clr-dark" style={{ width: '100%', bottom: '0' }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              size={{
                xs: 3,
                sm: 2
              }}>
              <a href="/">
                <img className="responsive-img footer-logo" alt="Foodnerd" src={loadImage('logo-nav.png')} />
              </a>
            </Grid>
            <Grid
              size={{
                xs: 3,
                sm: 3
              }}>
              <h4 className="white-text no-margin" style={{ fontSize: '1.64rem' }}>{I18nRails.t("footer.learn_more")}</h4>
              <List style={{ color: 'grey' }}>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/aboutus">About Us</a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/rider_profile/new">{I18nRails.t("footer.become_a_rider")}</a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/blogs">{I18nRails.t("footer.blog")}</a>
                  <a href="/blogs"></a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/customer-feedback">{I18nRails.t("footer.customer-feedback")}</a>
                  <a href="/customer-feedback"></a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/become-rider-benefits">{I18nRails.t("footer.become-rider-benefits")}</a>
                  <a href="/become-rider-benefits"></a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/gift-vouchers">{I18nRails.t("footer.gift-vouchers")}</a>
                  <a href="/gift-vouchers"></a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/how-to-order">{I18nRails.t("footer.how-to-order")}</a>
                  <a href="/how-to-order"></a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href="/faq">FAQ</a>
                </ListItem>
                {fndLocation && fndLocation.pages.map((page) => (
                  <ListItem key={'hom-' + page.id}>
                    <Link to={rh.location_page({ location_id: fndLocation.slug, page_id: page.slug }, Rails)}>
                      {page.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              size={{
                xs: 3,
                sm: 3
              }}>
              <h4 className="white-text no-margin" style={{fontSize: '1.64rem'}}> {I18nRails.t("footer.company")} </h4>
              <List style={{color: 'grey'}}>
                <ListItem>
                  <a href='/contact'> {I18nRails.t("footer.contact")} </a>
                </ListItem>
                <ListItem>
                  <a href='/careers'> {I18nRails.t("footer.careers")} </a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href='/privacypolicy'> Privacy Policy </a>
                </ListItem>
                <ListItem>
                  <a className="grey-text text-lighten-3" href='/termsofuse'> Terms of use </a>
                </ListItem>
              </List>
            </Grid>
            <Grid
              size={{
                xs: 3,
                sm: 3
              }}>
              <h4 className="white-text no-margin" style={{fontSize:'1.64rem'}}>
                { I18nRails.t('business_dashboard.footer.follow_us') }
              </h4>
              <div id="social">
                <a className="smGlobalBtn" href={countryConfig.facebook}>
                  <i className="fab fa-facebook-f white-text"></i>
                </a>
                <a className="smGlobalBtn" href={countryConfig.twitter}>
                  <i className="fab fa-twitter white-text"></i>
                </a>
                <a className="smGlobalBtn" href={countryConfig.instagram}>
                  <i className="fab fa-instagram white-text"></i>
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Grid
          size={{
            xs: 12,
            sm: 12
          }}>
          <div className="footer-copyright grey darken-3">
            <Container className="center">
              <p style={{textAlign: "center"}}>
                © {new Date().getFullYear()} <a href={Rails.host}>Foodnerd</a> {I18nRails.t("footer.rights_reserved")}
              </p>
            </Container>
          </div>
        </Grid>
      </div>
    </Paper>
  </>);
}

export default AppFooter;
