import React from "react";

import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

import AddressComponent from "./AddressComponent";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MapLocationPicker from "./Model/MapLocationPicker";
import { I18nRails } from "../../shared/rails-i18n-js";
import withParent from "./hoc/withParent";
import withAddresses from "./hoc/withAddresses";

const AddressesComponent = ({
  Rails,
  owner,
  selectedAddress,
  unmappedAddress,
  _unmappedAddress,
  selectAddress,
  addAddress, 
  cancelAddress,
  updateAddress,
  destroyAddress,
  enterNewAddress,
  address,
  toggleShowAddressEdit,
  isShowAddressEdit,
  saveMapAddress,
  showModal,
  cancelModal,
  newAddress,
  onMarkerDragEnd
}) => {

  return <>
    <Grid container>
      {owner && owner.addresses && !(!!unmappedAddress && selectedAddress && unmappedAddress.id == selectedAddress.id) &&
        owner.addresses.map((address, index) => (
          <Grid size={{xs: 3}} key={`address${index}`}>
            <Tooltip title="Tap to select address">
              <Card
                elevation={7}
                style={{
                  height: "290px",
                  marginLeft: "1%",
                  marginRight: "2%",
                  marginTop: "2%",
                  marginBottom: "2%",
                  cursor: "pointer"
                }}
                className={
                  !!selectedAddress && selectedAddress.id == address.id
                    ? "selected white"
                    : "white"
                }
              >
                <CardActions>
                  {!!selectedAddress && selectedAddress.id == address.id && (
                    <i style={{ color: "#7CB07F" }} className="material-icons">
                      check_circle
                    </i>
                  )}
                  {isShowAddressEdit(address.id) && (
                    <Tooltip title="Cancel Editing">
                      <CloseIcon
                        onClick={(e) => toggleShowAddressEdit(address.id)}
                        style={{ color: "#76baff" }}
                      />
                    </Tooltip>
                  )}
                  {!isShowAddressEdit(address.id) && (
                    <div className="btn" onClick={(e) => toggleShowAddressEdit(address.id)}>
                      <Tooltip title="Edit Address">
                        <i style={{ color: "#76baff" }} className="material-icons">
                          edit
                        </i>
                      </Tooltip>
                    </div>
                  )}
                  <div className="btn" onClick={(e) => destroyAddress(address)}>
                    <Tooltip title="Delete">
                      <i style={{ color: "#FA6961" }} className="material-icons">
                        delete
                      </i>
                    </Tooltip>
                  </div>
                </CardActions>
                <Grid container>
                  <Grid size={{xs: 12}}>
                    { !isShowAddressEdit(address.id) ? (
                      <div className="address-selection-address" onClick={(e) => selectAddress(address)} style={{margin: "0"}}>
                        <div className="row person-name">
                          <p><b>{address.name_door}</b></p>
                        </div>
                        <div className="row address-1">
                          <p>{address.street}</p>
                        </div>
                        <div className="row address-2">
                          <p>{address.street2}</p>
                        </div>
                        <div className="row address-city">
                          <p>{address.city} {address.zip}</p>
                        </div>
                      </div>
                    ) : (
                      <AddressComponent 
                        address={address} 
                        addAddress={addAddress} 
                        cancelAddress={cancelAddress} 
                        updateAddress={updateAddress} 
                        Rails={Rails} />
                    )}
                  </Grid>
                </Grid>

              </Card>
            </Tooltip>
          </Grid>
        ))
      }
      { !!newAddress && (
        <div className="row choose-address">
          <AddressComponent
            address={{}}
            addAddress={addAddress}
            cancelAddress={cancelAddress}
            updateAddress={updateAddress}
            Rails={Rails}
          />
        </div>
      )}

      { !!unmappedAddress && (          
        <div>
          <Grid container>
            <Grid size={{xs: 8}}></Grid>
            <MapLocationPicker
              saveMapAddress={saveMapAddress}
              showModal={showModal}
              cancelModal={cancelModal}
            />
            <Grid size={{xs: 12}}>
              <Button 
                className="btn-second" 
                style={{marginBottom:'2%'}} 
                onClick={(e) => setShowModal(true)}
              >
                Open Map
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{marginTop:'2%'}}>
            <Grid size={{xs: 3}}></Grid>
            <Grid size={{xs: 3}}></Grid>
          </Grid>
        </div>
      )}
      { !!_unmappedAddress && (
        <Grid container> 
          <Grid size={{xs: 12}}>
          </Grid>
        </Grid>
      )}          
    </Grid>
    <Grid container>
      { !!_unmappedAddress && (
        <>
          <Grid size={{xs: 12}}>
            <MapLocationPicker
              saveMapAddress={saveMapAddress}
              showModal={showModal}
              cancelModal={cancelModal}
            />
          </Grid>
          <Grid size={{xs: 12}}>
            <Button 
              className="btn-second" 
              style={{marginBottom:'2%'}} 
              onClick={(e) => setShowModal(true)}
            >
              Open Map
              </Button>
          </Grid>
        </> 
      )}
      <Grid className="col-xs-4" style={{marginTop:'2%'}}>
        { !newAddress && (
          <Button className="btn-second order-mode-btn" style={{marginRight:'10px'}} onClick={(e) => enterNewAddress()}>{I18nRails.t('client.progress.addresses.new')}</Button>
        )}
      </Grid>
    </Grid>
  </>;
};

export default withParent(withAddresses(AddressesComponent));