import React, { Component, useRef } from "react";
import ParentComponent from "./ParentComponent";
import LocationCard from "./LocationCard";
import LocationResource from "../resources/location";
import * as rh from "./helpers/routes";
import Grid from "@mui/material/Grid2";
import { withLocation } from "../../react-pos/components/withLocation";
import { withParams } from "../../react-pos/components/withParams";
import withParent from "./hoc/withParent";
import { useSsrProps } from "../SsrContext";


const FavoriteLocations = props => {
  const {
    favorites
  } = props;

  const ssrProps = useSsrProps()

  const Location = LocationResource.get(ssrProps.Rails);
  var favs = [];
  favorites.forEach((fav) => {
    var fdlocation = new Location(fav.obj);
    fdlocation.red = true;
    favs.push(fdlocation);
  });
  return (
    (<Grid container spacing={4} style={{marginTop: '5%', paddingBottom: '5%', paddingLeft: '3%', paddingRight: '3%', width: '100%'}}>
      {favs.map(fav => (
        <Grid size={4}>
          <LocationCard
            id={fav.id}
            inFavoriteRoute={true}
            isLiked={props.isLiked}
            fndLocation={fav}
            toggleFavoriteLike={props.toggleFavoriteLike}
            latLng={props.latLng}
            Rails={ssrProps.Rails}
          />
        </Grid>
      ))}
    </Grid>)
  );
  // return pug`
  //   Grid(container spacing=4 style={marginTop:'5%',paddingBottom:'5%',paddingLeft:'3%', paddingRight:'3%',width:'100%'})
  //     each fav in favs
  //       Grid(item xs=4)
  //         LocationCard(fav.id, inFavoriteRoute=true, isLiked=this.props.isLiked, fndLocation=fav, toggleFavoriteLike=this.props.toggleFavoriteLike, latLng=this.props.latLng, Rails=this.props.Rails)
  // `;
};

export default withParent(FavoriteLocations);

