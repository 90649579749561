import React from "react";
// import AppCartConfirm from './components/AppCartConfirm'
// import AppCheckoutAddresses from './components/AppCheckoutAddresses'
import AppReload from "./components/AppReload";
import AppFavorites from "./components/AppFavorites";
import AppLocationAbout from "./components/AppLocationAbout";
import AppOrders from "./components/AppOrders";
import AppResetPassword from "./components/AppResetPassword";
import AppUpdatePassword from "./components/AppUpdatePassword";
import AppUpdates from "./components/AppUpdates";
import AppUserAddresses from "./components/AppUserAddresses";

// import AppCheckout from "./components/AppLocationCart";
import AppCart from "./components/AppCart";
import AppLogin from "./components/AppLogin";
import AppMain from "./components/AppMain";
import AppRegister from "./components/AppRegister";
import AppLocations from "./components/AppLocations";
import AppLocation from "./components/AppLocation";
import AppLocationCart from "./components/AppLocationCart";
import AppLocationBlogs from "./components/AppLocationBlogs";
import AppOrder from "./components/AppOrder";
import AppLocationOrders from "./components/AppLocationOrders";
import AppLocationReviews from "./components/AppLocationReviews";
import AppMenuItemReviews from "./components/AppMenuItemReviews";
import AppMenuReviews from "./components/AppMenuReviews";
import AppProfile from "./components/AppProfile";

export const locationRoutesOnly = [
  {
    path: '/editor/blogs/new',
    Component: AppReload,
  },
  {
    path: '/editor/pages/new',
    Component: AppReload,
  },
  {
    path: '/about',
    Component: AppReload,
  },
  {
    path: '/orders',
    Component: AppLocationOrders,
  },
  {
    path: '/blogs',
    Component: AppLocationBlogs,
  },
  {
    path: '/pages/:page_id',
    Component: AppReload,
  },
  {
    path: '/reviews',
    Component: AppLocationReviews,
  },
  {
    path: '/cart',
    Component: AppLocationCart,
  },
  {
    path: '/menu_reviews/:id',
    Component: AppMenuReviews,
  },
  {
    path: '/menus/:menu_id/menu_item_reviews/:id',
    Component: AppMenuItemReviews,
  },
  {
    path: '/login/:state',
    //Component: ngular2ReactComponent('appLogin', AppLo)
    Component: AppLogin,
  },

];

export const siteRoutesOnly = [
  {
    path: '/blogs',
    Component: AppReload,
  },
  {
    path: '/favorites',
    Component: AppFavorites,
  },
  {
    path: '/testimonials',
    Component: AppReload,
  },
  {
    path: '/contact',
    Component: AppReload,
  },
  {
    path: '/pricing',
    Component: AppReload,
  },
  {
    path: '/careers',
    Component: AppReload,
  },
  {
    path: '/how-it-works',
    Component: AppReload,
  },
  {
    path: '/editor/locations/:location_id/blogs/new',
    Component: AppReload,
  },
  {
    path: '/editor/locations/:location_id/pages/new',
    Component: AppReload,
  },
  {
    path: '/locations',
    Component: AppLocations,
    data: {
      bodyClass: "home-bg",
    },    
  },
  {
    path: '/locations/:location_id',
    Component: AppLocation,
  },
  {
    path: '/locations/:location_id/about',
    Component: AppLocationAbout,
  },
  {
    path: '/locations/:location_id/orders',
    Component: AppLocationOrders,
  },
  {
    path: '/locations/:location_id/blogs',
    Component: AppLocationBlogs,
  },
  {
    path: '/locations/:location_id/cart',
    Component: AppLocationCart,
  },
  {
    path: '/locations/:location_id/pages/:page_id',
    Component: AppReload,
  },
  {
    path: '/locations/:location_id/reviews',
    Component: AppLocationReviews,
  },
  {
    path: '/locations/:location_id/menu_reviews/:id',
    Component: AppMenuReviews,
  },
  {
    path: '/locations/:location_id/menus/:menu_id/menu_item_reviews/:id',
    Component: AppMenuItemReviews,
  },
  {
    path: '/locations/:location_id/login/:state',
    //Component: ngular2ReactComponent('appLogin', AppLo)
    Component: AppLogin,
  }
];

const commonRoutes = [
  {
    path: '/manager',
    Component: AppReload,
  },
  {
    path: '/manager/login',
    Component: AppLogin,
  },
  {
    path: '/manager/pos',
    Component: AppReload,
  },
  {
    path: '/manager/:route',
    Component: AppReload,
  },
  {
    path: '/manager/:routes/:route',
    Component: AppReload,
  },
  {
    path: '/admin',
    Component: AppReload,
  },
  {
    path: '/business',
    Component: AppReload,
  },
  {
    path: '/orders',
    Component: AppOrders,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "My Orders",
      },
    },
  },
  {
    path: '/updates',
    Component: AppUpdates,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "Updates",
      },
    },
  },
  {
    path: '/cart',
    Component: AppCart,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "Cart",
      },
    },
  },
  {
    path: '/orders/:id',
    Component: AppOrder,
  },
  {
    path: '/apps/client/locations',
    Component: AppLocations,
  },
  {
    path: '/apps/client',
    Component: AppLocations,
  },
  {
    path: '/users/me',
    Component: AppProfile,
  },
  {
    path: '/addresses',
    Component: AppUserAddresses,
  },
  {
    path: '/reorder/:order_id',
    Component: AppLocationCart,
  },
  // {
  //   exact: true
  //   path: (parentRoute) -> "#{parentRoute}/confirm"
  //   Component: AppCartConm
  //   data:
  //     bodyClass: 'frontpage-bg'
  //     meta:
  //       'title': 'Confirm Order'
  // }
  // {
  //   exact: true
  //   path: (parentRoute) -> "#{parentRoute}/locations/:location_id/confirm"
  //   Component: AppCartConm
  //   data:
  //     bodyClass: 'frontpage-bg'
  //     meta:
  //       'title': 'Confirm Order'
  // }
  //  {
  //    exact: true
  //    path: (parentRoute) -> "#{parentRoute}/access_token=:response"
  //    controller: [
  //      '$state'
  //      'AccessTokenFactory'
  //      ($state, AccessTokenFactory) ->
  //        token = $state.params.response.match(/^(.*?)&/)[1]
  //        AccessTokenFactory.set(token)
  //        $state.go 'index'
  //    ]
  //    data:
  //      bodyClass: 'frontpage-bg'
  //      meta:
  //        'title': 'Access Token'
  //  }
  {
    path: 'login',
    Component: AppLogin,
  },
  {
    path: '/join',
    Component: AppRegister,
  },
  {
    path: 'forgot_password',
    Component: AppResetPassword,
  },
  {
    path: 'reset/:token',
    Component: AppUpdatePassword,
  }
];

export const locationRoutes = [
  // {
  //   path: '/',
  //   Component: AppLocation,
  //   children: [
      {path: '/', Component: AppLocation}, ...commonRoutes, ...locationRoutesOnly
  //   ]
  // }
];
export const siteRoutes = [
  // {
  // path: '/',
  // Component: AppMain,
  // children: [
    {path: '/', Component: AppMain},...commonRoutes, ...siteRoutesOnly
//   ]
// }
];
export default (Rails) => {
  if (!!Rails.domain_place_id && Rails.domain_place_type === "Location") {
    return locationRoutes;
  } else {
    return siteRoutes;
  }
};

//www.islamabadchicken/products - location domain routes
//www.howmuch.pk/islamabad-chicken/products - main domain routes
