import isMobile from "ismobilejs";
import React, { useEffect, useRef, useState } from "react";

import LocationLayout from "./LocationLayout";

import CartService from "../services/CartService";
import MenuItemResource from "../resources/menu-item";
import LocationMenu from "./templates/LocationMenu";
import MenuItemModals from "./templates/MenuItemModals";
import sweetalert2 from "sweetalert2";
import FavoriteService from "../services/FavoriteService";
import { I18nRails } from "../../shared/rails-i18n-js";

import UserResource from "../resources/user";

import { isLoggedIn } from "./helpers/utils";
import { isUserLiked, setUserFriendsFavorites } from "../models/user";
import { isLocationOnlineOrdersActive, isLocationOpenNow, locationTimeToday } from "../models/location";
import withParent from "./hoc/withParent";
import { resetBasketTotals } from "./Model/basket";
import { useNavigate } from "react-router";
import { getLocationMetaTags } from "./helpers/react";
import { getComponentOptionValueFromOption } from "../models/component_option_value";
import { useSsrProps } from "../SsrContext";

const AppLocation = ({
  locationId,
  initLocation,
  fndLocation,
  initUser,
  basket,
  cartService,
  changeQuantity,
  onSubmitReview,
  openReviewModal,
  setLatLng,
  toggleLike,
  user,
  setUser,
  removeBasketItem,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  ...others
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [reviewContent, setReviewContent] = useState("");
  const [reviews, setReviews] = useState([]);
  const [review_title, setReview_title] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const [ingredientListCount, setIngredientListCount] = useState(0);
  const [ingredientKey, setIngredientKey] = useState(0);
  const [ingredientListKey, setIngredientListKey] = useState(0);
  const [error_message, setErrorMessage] = useState();
  const [selected_option, setSelectedOption] = useState();
  const [selectedMenuItemIngredients, setSelectedMenuItemIngredients] = useState();
  const [loading, setLoading] = useState();
  const [addToCartDialog, setAddToCartDialog] = useState(false)
  const [addReviewDialog, setAddReviewDialog] = useState(false);
  const [menuReviewDialog, setMenuReviewDialog] = useState(false);
  const [menu, setMenu] = useState();
  const [menuItem, setMenuItem] = useState();
  const [selectedDialogItem, setSelectedDialogItem] = useState();

  const selectedMenuItemsRef = useRef({});
  const selectedMenuItemRef = useRef({});
  const menuItemDetailsRef = useRef({});
  const review = useRef({ content: "" });
  const selectedIngredientsRef = useRef(undefined);
  const ingredientsForOption = useRef(undefined);
  const includedIngredients = useRef(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const order_items = cartService.get(locationId).items;
    for (let order_item of order_items) {
      if (order_item.type === "OrderItem") {
        selectedMenuItemsRef.current[order_item.menu_item.id] =
          order_item.menu_item;
        selectedMenuItemsRef.current[order_item.menu_item.id]["option"] =
          order_item.menu_item.component_option_value;
      }
    }

    //TODO deprecate pushpin
    initPushpin();
  }, []);

  useEffect(() => {
    if (!locationId) {
      navigate("/locations");
      return;
    }

    initLocation((location) => {
      if (isLoggedIn(Rails)) {
        if (!user) {
          initUser((user) => {
            if (!!user.provider) {
              loadFriendsFavorites(location);
            }
          });
        } else {
          if (!!user.provider) {
            loadFriendsFavorites(location);
          }
        }
      }
    });

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  const loadFriendsFavorites = (location) => {
    FavoriteService.location_friends_favorites(
      location.slug,
      Rails
    ).then(
      (friends_favorites) => {
        FavoriteService.location_friends(location.slug, Rails).then(
          (locations_friends) => {
            const updatedUser = {...user}
            setUserFriendsFavorites(updatedUser, friends_favorites.data);
            setUserLocationsFriends(updatedUser, locations_friends.data);
            setUser(updatedUser)
          }
        );
      },
      (error) => {
        console.log("Got error:", error);
        sweetalert2.fire(error.response.data.error);
      }
    );
  };

  const resourceLoaded = () => {
    return !!fndLocation;
  };

  const closeMenuItemModal = () => {
    setAddToCartDialog(false)
    setAddReviewDialog(false)
    setMenuReviewDialog(false);
  };

  const openMenuItemModal = (menu_item, menu) => {
    setAddToCartDialog(true)
    setMenu(menu);
    setMenuItem(menu_item);
    getMenuItemDetails(menu_item);
    setSelectedDialogItem(menu_item);
  };

  const closeReviewDialog = () => {
    // this.addToCartDialog = false;
    // TODO we need to re-render compoenent to close modal
    //because it is handle with global variable instead of state
    setShowReviewDialog(false);
  };
  
  const validateAddToBasket = (menu_item, parent) => {
    //# Checks for min max ingredients and option values
    if (!isLocationOnlineOrdersActive(fndLocation)) {
      sweetalert2.fire(
        `${ fndLocation.name } ${ I18nRails.t(
          "venue.alertmessage.deactivate"
        ) }`
      );
      return false;
    }
    if (menu_item.status === "soldout") {
      sweetalert2.fire(I18nRails.t("client.basket.soldout")); //, parent)
      return false;
    }
    if (!isLocationOpenNow(fndLocation)) {
      sweetalert2.fire(
        `${ I18nRails.t(
          "venue.alertmessage.closed"
        ) } ${ locationTimeToday(fndLocation) }.`
      ); //, parent)

      return false;
    }
    //      if @CartService.getLocation() != null
    //        if @CartService.getLocation().id != @state.location.id
    //          $translate('venue.alertmessage.twoplaces').then(
    //            (success) =>
    //              @sweetalert2.fire("#{success}" )
    //          )
    //          return false

    selectedMenuItemsRef.current[menu_item.id] =
      menuItemDetailsRef.current[menu_item.id];
    //      has_selected_ingredients = @selected_menu_items[menu_item.id]['menu_item_ingredients'] && Object.keys(@selected_menu_items[menu_item.id]['menu_item_ingredients']).length > 0
    const selected_option_value =
      selectedMenuItemsRef.current[menu_item.id]["option"];
    if (
      menu_item.component_option_values.length === 1 &&
      !selected_option_value
    ) {
      if (
        menu_item.component_option_values[0].available_option_values.length ===
        1
      ) {
        toggleComponentOptionValue(
          menu_item,
          menu_item.component_option_values[0].available_option_values[0]
        );
      }
    }
    //          if @selected_menu_items[menu_item.id]['option']
    //            selected_option_value = @selected_menu_items[menu_item.id]['option']
    //      if @buttonText=='Bestille'
    //        if menu_item.ingredient_lists.length > 0 && !has_selected_ingredients && menu_item.component_option_values.length > 0 && !selected_option_value
    //          return @sweetalert2.fire("Please select an option and ingredients.")
    //        else
    if (
      menu_item.component_option_values.length > 0 &&
      !selected_option_value
    ) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.selectoption"));

      return false;
    }

    const minIngredientHash = {};
    for (let key of Object.keys(
      menuItemDetailsRef.current[menu_item.id].ingredient_lists
    )) {
      if (
        !!menuItemDetailsRef.current[menu_item.id].ingredient_lists[key]
          .option_value_id
      ) {
        if (
          !!selected_option_value &&
          menuItemDetailsRef.current[menu_item.id].ingredient_lists[key]
            .option_value_id === selected_option_value.option_value_id
        ) {
          if (
            Object.keys(
              menuItemDetailsRef.current[menu_item.id].ingredient_lists[key]
                .menu_item_ingredients
            ).length > 0
          ) {
            if (
              menuItemDetailsRef.current[menu_item.id].ingredient_lists[key].min > 0
            ) {
              minIngredientHash[key] =
                menuItemDetailsRef.current[menu_item.id].ingredient_lists[key];
            }
          }
        }
      }
    }
    if (Object.keys(minIngredientHash).length > 0) {
      for (let key2 of Object.keys(minIngredientHash)) {
        const { min } = minIngredientHash[key2];
        const name = minIngredientHash[key2].title;
        let count = 0;
        for (let key of Object.keys(
          menuItemDetailsRef.current[menu_item.id].component_option_values
        )) {
          for (let key1 of Object.keys(
            menuItemDetailsRef.current[menu_item.id].component_option_values[key]
              .available_option_values
          )) {
            const { option_value_id } =
              menuItemDetailsRef.current[menu_item.id].component_option_values[key]
                .available_option_values[key1];
            if (
              !!selected_option_value &&
              selected_option_value.option_value_id === option_value_id
            ) {
              for (let key3 of Object.keys(
                selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
                option_value_id
                ]
              )) {
                if (
                  selectedMenuItemsRef.current[menu_item.id][
                    "menu_item_ingredients"
                  ][option_value_id][key3].ingredient_list_id ===
                  minIngredientHash[key2].id
                ) {
                  count = count + 1;
                }
              }
              if (count < min) {
                let message = I18nRails.t(
                  "venue.alertmessage.select_ingredients_min",
                  { min_ingredients: min, ingredient_list: name }
                );
                sweetalert2.fire(message);
                return false;
              }
            }
          }
        }
      }
    }

    //        else if menu_item.ingredient_lists.length > 0 && !has_selected_ingredients
    //          return @sweetalert2.fire("Please select ingredients.")
    //        else if !!@selected_menu_items[menu_item.id].menu_item_ingredients
    //          if Object.keys(@selected_menu_items[menu_item.id].menu_item_ingredients).length < @menu_item_details[menu_item.id].ingredient_lists[0].min
    //            return @sweetalert2.fire("Please select at least #{@menu_item_details[menu_item.id].ingredient_lists[0].min} ingredients.")
    return true;
  };

  const addToBasket = (menu_item, menu, event) => {
    let parentElement, selected_option;
    if (!!event) {
      ({ parentElement } = event.target);
    }
    if (!validateAddToBasket(menu_item, parentElement)) {
      return;
    }
    const selected_menu = { ...menu };
    delete selected_menu["menu_items"];
    //# -------------------------
    const menuItemIngredients = [];
    if (!!menu_item) {
      if (selectedMenuItemsRef.current[menu_item.id]) {
        let menu_item_ingredients;
        selected_option = selectedMenuItemsRef.current[menu_item.id]["option"];
        if (selected_option) {
          menu_item_ingredients =
            selectedMenuItemsRef.current[menu_item.id].menu_item_ingredients[
            selected_option.option_value_id
            ];
        } else {
          ({ menu_item_ingredients } = selectedMenuItemsRef.current[menu_item.id]);
        }

        for (let ingredient_id in menu_item_ingredients) {
          const ingredient = menu_item_ingredients[ingredient_id];
          menuItemIngredients.push(ingredient);
        }
      }
    }
    setSelectedMenuItemIngredients(menuItemIngredients);

    cartService.addQuantity(
      fndLocation,
      menu_item,
      selected_option ? getComponentOptionValueFromOption(selected_option) : null,
      menuItemIngredients,
      selected_menu
    );
    if (!basket) {
      basket = cartService.get(locationId, true);
    }
    basket.items = cartService.get(
      locationId
    ).items;

    // this.setState({
    //   basket: this.initBasket()
    // }, () => {
    // })
    resetBasketTotals(basket, CartService.get(Rails));

    // this.setState({
    //   basket: {
    //     ...this.state.basket,
    //     items: this.CartService.get(this.state.location_id).items
    //   }
    // })
    // this.setState({
    //   basket: this.state.basket,
    //   numberOfCartItems: this.numberOfCartItems,
    //   total: this.total
    // });
    closeMenuItemModal();
  };

  const addToBasketDirect = (menu_item, menu, event) => {
    //fetch menu_item_details first before adding the item to basket
    getMenuItemDetails(menu_item, () =>
      addToBasket(menu_item, menu, event)
    );
  }

  function removeFromBasketDirect(menu_item, event) {
    const cartService = CartService.get(Rails)
    if (cartService.exists(menu_item, "", "")) {
      cartService.subtractQuantity(fndLocation, menu_item, "", "");
    } else {
      cartService.subtractMenuItemQuantity(fndLocation, menu_item);
    }

    closeMenuItemModal();
  }

  function removeFromBasket(menu_item) {
    let selected_option;
    const menuItemIngredients = [];
    if (selectedMenuItemsRef.current[menu_item.id]) {
      let menu_item_ingredients;
      selected_option = selectedMenuItemsRef.current[menu_item.id]["option"];
      if (selected_option) {
        menu_item_ingredients =
          selectedMenuItemsRef.current[menu_item.id].menu_item_ingredients[
          selected_option.option_value_id
          ];
      } else {
        ({ menu_item_ingredients } = selectedMenuItemsRef.current[menu_item.id]);
      }

      for (let ingredient_id in menu_item_ingredients) {
        const ingredient = menu_item_ingredients[ingredient_id];
        menuItemIngredients.push(ingredient);
      }
    }
    setSelectedMenuItemIngredients(menuItemIngredients);

    const cartService = CartService.get(Rails)
    if (
      cartService.exists(
        menu_item,
        selected_option ? getComponentOptionValueFromOption(selected_option) : null,
        menuItemIngredients
      )
    ) {
      cartService.subtractQuantity(
        fndLocation,
        menu_item,
        selected_option ? getComponentOptionValueFromOption(selected_option) : null,
        menuItemIngredients
      );
    } else {
      cartService.subtractMenuItemQuantity(fndLocation, menu_item);
    }
  }

  const existsInBasket = (menu_item) => {
    const cartService = CartService.get(Rails)
    return cartService.existsMenuItem(menu_item, locationId);
  };

  function initPushpin() {
    if (typeof window !== "undefined") {
      import("jquery").then((jquery) => {
        global.$ = jquery.default;
        document.addEventListener("scroll", function() {
          if (isMobile(window.navigator.userAgent).any) {
            if ($(document).scrollTop() < 200) {
              document.querySelectorAll(".dish-list").forEach(el => el.style.marginTop = "0px");
              document.querySelectorAll(".dish-list").forEach(el => el.style.position = "relative");
              document.querySelectorAll(".location_cart").forEach(el => el.style.position = "relative");
              document.querySelectorAll(".location_cart").forEach(el => el.style.marginTop = "0px");
              document.querySelectorAll(".location_cart").forEach(el => el.style.width = document.querySelector(".location_cart").parentElement.clientWidth)
            } else {
              document.querySelectorAll(".dish-list").forEach(el => el.style.marginTop = "-533px ");
              document.querySelectorAll(".dish-list").forEach(el => el.style.position = "fixed");
              document.querySelectorAll(".location_cart").forEach(el => el.style.width = document.querySelector(".location_cart").parentElement.clientWidth);
              document.querySelectorAll(".location_cart").forEach(el => el.style.position = "fixed");
              document.querySelectorAll(".location_cart").forEach(el => el.style.marginTop = "-420px"); 
            }
          } else {
            if ($(document).scrollTop() < 500) {
              document.querySelectorAll(".dish-list").forEach(el => el.style.marginTop = "0px");
              document.querySelectorAll(".dish-list").forEach(el => el.style.position = "absolute");
              document.querySelectorAll(".location_cart").forEach(el => el.style.position = "absolute");
              document.querySelectorAll(".location_cart").forEach(el => el.style.marginTop = "0px");              
              document.querySelectorAll(".location_cart").forEach(el => el.style.width = document.querySelector(".location_cart").parentElement.clientWidth);
            } else {
              document.querySelectorAll(".dish-list").forEach(el => el.style.marginTop = "-532px ");
              document.querySelectorAll(".dish-list").forEach(el => el.style.position = "fixed");
              document.querySelectorAll(".location_cart").forEach(el => el.style.width = document.querySelector(".location_cart").parentElement.clientWidth);
              document.querySelectorAll(".location_cart").forEach(el => el.style.position = "fixed");
              document.querySelectorAll(".location_cart").forEach(el => el.style.marginTop = "-420px");
              if (
                $(window).height() + $(window).scrollTop() >
                $(document).height() - 200
              ) {
                document.querySelectorAll(".location_cart").forEach(el => el.style.position = "absolute");  
              }
            }
          }
        });
      });
    }
  }

  const showMessageAlert = (message, params, event) => {
    sweetalert2.fire(I18nRails.t(message, params));
  }

  const getSelectedMenuItemIngredients = (menu_item) => {

    let result;
    if (!!!menu_item) {
      return;
    }
    if (!menuItemDetailsRef.current) {
      return [];
    }
    if (!selectedMenuItemsRef.current[menu_item.id]) {
      return [];
    }
    if (!selectedMenuItemsRef.current[menu_item.id]["option"]) {
      return [];
    }
    const selected_option_value =
      selectedMenuItemsRef.current[menu_item.id]["option"];
    if (!!menuItemDetailsRef.current[menu_item.id]) {
      selectedMenuItemsRef.current[menu_item.id] =
        menuItemDetailsRef.current[menu_item.id];
    }
    if (!!selectedMenuItemsRef.current[menu_item.id]) {
      if (!selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"]) {
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] = {};
      }
    }
    if (!!selectedMenuItemsRef.current[menu_item.id]) {
      result =
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selected_option_value.option_value_id
        ] ||
        (selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
          selected_option_value.option_value_id
        ] = {});
    }

    selectedIngredientsRef.current = result;

    return result;
  }

  const addMenuItemIngredient = (menu_item, menu_item_ingredient, ingredient_list, event) => {
    if (
      ingredientListCount >= ingredient_list.max &&
      ingredientListKey === ingredient_list.id
    ) {
      setErrorMessage(I18nRails.t("ingredients_full.no_ingredients", {
        max: ingredient_list.max,
        list: ingredient_list.title,
      }));
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }

    if (
      !menu_item_ingredient.customizable ||
      !ingredient_list.customizable_count > 0
    ) {
      sweetalert2.fire(I18nRails.t("non-customizable menu item ingredient"));
      return;
    }

    if (!isLocationOnlineOrdersActive(fndLocation)) {
      sweetalert2.fire(
        `${ fndLocation.name } ${ I18nRails.t(
          "venue.alertmessage.onlineordering"
        ) }`
      );
    }
    if (!isLocationOpenNow(fndLocation)) {
      sweetalert2.fire(
        `${ I18nRails.t(
          "venue.alertmessage.closed"
        ) } ${ locationTimeToday(fndLocation) }.`
      );
      return false;
    }
    selectedMenuItemsRef.current[menu_item.id] =
      menuItemDetailsRef.current[menu_item.id];
    if (!selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"]) {
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] = {};
    }
    const selected_option_value =
      selectedMenuItemsRef.current[menu_item.id]["option"];
    const option_menu_item_ingredients =
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
      selected_option_value.option_value_id
      ] ||
      (selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selected_option_value.option_value_id
      ] = {});

    //if option_menu_item_ingredients[menu_item_ingredient.id]['count'] >= menu_item_ingredient.maximum

    if (!option_menu_item_ingredients[menu_item_ingredient.id]) {
      option_menu_item_ingredients[menu_item_ingredient.id] =
        menu_item_ingredient;
      option_menu_item_ingredients[menu_item_ingredient.id]["count"] = 0;
    }

    if (
      !!menu_item_ingredient.maximum &&
      option_menu_item_ingredients[menu_item_ingredient.id]["count"] >=
      menu_item_ingredient.maximum
    ) {
      sweetalert2.fire(
        I18nRails.t("menuitem.max_count_reached", {
          max: menu_item_ingredient.maximum,
          name: menu_item_ingredient.name,
        })
      );
    }

    // let ingredientListCount = 0;
    for (let menu_item_ingredient_key of Object.keys(
      option_menu_item_ingredients
    )) {
      if (
        option_menu_item_ingredients[menu_item_ingredient_key]
          .ingredient_list_id === ingredient_list.id
      ) {
        // setIngredientKey(ingredientKey + 1);
        setIngredientListCount(ingredientListCount + 1);
      }
    }

    let menu_item_ingredients_key = Object.keys(
      option_menu_item_ingredients
    )[0];

    let ingredient_list_key =
      option_menu_item_ingredients[menu_item_ingredients_key]
        .ingredient_list_id;

    setIngredientListKey(ingredient_list_key);

    if (
      ingredientListCount >= ingredient_list.max &&
      ingredientListKey === ingredient_list.id
    ) {
      setErrorMessage(I18nRails.t("ingredients_full.no_ingredients", {
        max: ingredient_list.max,
        list: ingredient_list.title,
      }));
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } else {
      if (
        option_menu_item_ingredients[menu_item_ingredient.id]["count"] <
        ingredient_list.max
      ) {
        option_menu_item_ingredients[menu_item_ingredient.id]["count"] += 1;
        //            delete @selected_menu_items[menu_item.id]['menu_item_ingredients'][menu_item_ingredient.id]
        if (
          !!menu_item_ingredient.maximum &&
          option_menu_item_ingredients[menu_item_ingredient.id]["count"] ===
          menu_item_ingredient.maximum
        ) {
          for (let ingredient_list_key of Object.keys(
            ingredientsForOption.current[menu_item.id]
          )) {
            if (
              ingredientsForOption.current[menu_item.id][ingredient_list_key]
                .id === menu_item_ingredient.ingredient_list_id
            ) {
              for (let menu_item_ingredient_key of Object.keys(
                ingredientsForOption.current[menu_item.id][ingredient_list_key][
                "menu_item_ingredients"
                ]
              )) {
                if (
                  ingredientsForOption.current[menu_item.id][ingredient_list_key][
                    "menu_item_ingredients"
                  ][menu_item_ingredient_key].id === menu_item_ingredient.id
                ) {
                  ingredientsForOption.current[menu_item.id][ingredient_list_key][
                    "menu_item_ingredients"
                  ].splice(menu_item_ingredient_key, 1);
                  decideButton(
                    menu_item,
                    selectedMenuItemsRef.current[menu_item.id]["option"],
                    selectedMenuItemsRef.current[menu_item.id][
                    "menu_item_ingredients"
                    ][
                    selectedMenuItemsRef.current[menu_item.id]["option"]
                      .option_value_id
                    ]
                  );
                  return;
                } else {
                  console.log("no match");
                }
              }
            }
          }
        }
      }
      return decideButton(
        menu_item,
        selectedMenuItemsRef.current[menu_item.id]["option"],
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
        ]
      );
    }
  }

  const removeMenuItemIngredient = (menu_item, menu_item_ingredient) => {
    let index;
    const selected_option_value =
      selectedMenuItemsRef.current[menu_item.id]["option"];
    if (!selectedMenuItemsRef.current[menu_item.id]) {
      selectedMenuItemsRef.current[menu_item.id] = {};
    }
    if (!selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"]) {
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] = {};
    }
    const option_menu_item_ingredients =
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
      selected_option_value.option_value_id
      ] ||
      (selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selected_option_value.option_value_id
      ] = {});
    //inserting back upon delete from above also delete for max count 1 ingredients
    console.log(menu_item_ingredient);
    if (option_menu_item_ingredients[menu_item_ingredient.id]["count"] === 1) {
      delete option_menu_item_ingredients[menu_item_ingredient.id];
      setIngredientListCount(0);
      for (let ingredient_list_key of Object.keys(
        ingredientsForOption.current[menu_item.id]
      )) {
        if (
          ingredientsForOption.current[menu_item.id][ingredient_list_key].id ===
          menu_item_ingredient.ingredient_list_id
        ) {
          let menu_item_ingredient_exists = false;
          for (let menu_item_ingredient_key of Object.keys(
            ingredientsForOption.current[menu_item.id][ingredient_list_key][
            "menu_item_ingredients"
            ]
          )) {
            if (
              ingredientsForOption.current[menu_item.id][ingredient_list_key][
                "menu_item_ingredients"
              ][menu_item_ingredient_key].id === menu_item_ingredient.id
            ) {
              menu_item_ingredient_exists = true;
            }
          }
          if (menu_item_ingredient_exists !== true) {
            index =
              ingredientsForOption.current[menu_item.id][ingredient_list_key][
                "menu_item_ingredients"
              ].length;
            ingredientsForOption.current[menu_item.id][ingredient_list_key][
              "menu_item_ingredients"
            ][index] = menu_item_ingredient;
            decideButton(
              menu_item,
              selectedMenuItemsRef.current[menu_item.id]["option"],
              selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
              selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
              ]
            );
            return;
          }
          decideButton(
            menu_item,
            selectedMenuItemsRef.current[menu_item.id]["option"],
            selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
            selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
            ]
          );
          return;
        }
      }
      return decideButton(
        menu_item,
        selectedMenuItemsRef.current[menu_item.id]["option"],
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
        ]
      );
    } else {
      if (option_menu_item_ingredients[menu_item_ingredient.id]["count"] > 1) {
        if (
          option_menu_item_ingredients[menu_item_ingredient.id]["count"] ===
          menu_item_ingredient.maximum
        ) {
          for (let ingredient_list_key of Object.keys(
            ingredientsForOption.current[menu_item.id]
          )) {
            if (
              ingredientsForOption.current[menu_item.id][ingredient_list_key]
                .id === menu_item_ingredient.ingredient_list_id
            ) {
              index =
                ingredientsForOption.current[menu_item.id][ingredient_list_key][
                  "menu_item_ingredients"
                ].length;
              ingredientsForOption.current[menu_item.id][ingredient_list_key][
                "menu_item_ingredients"
              ][index] = menu_item_ingredient;
            }
          }
        }
        option_menu_item_ingredients[menu_item_ingredient.id]["count"] =
          option_menu_item_ingredients[menu_item_ingredient.id]["count"] - 1;
        decideButton(
          menu_item,
          selectedMenuItemsRef.current[menu_item.id]["option"],
          selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
          selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
          ]
        );
      }
      return decideButton(
        menu_item,
        selectedMenuItemsRef.current[menu_item.id]["option"],
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
        ]
      );
    }
  }

  const decideButton = (menu_item, selected_option, selectedMenuItemIngredients) => {
    setMenuItem(menu_item);
    setSelectedOption(selected_option);
    setSelectedMenuItemIngredients(selectedMenuItemIngredients);
  }

  const class_for_option_value = (available_option_value, menu_item) => {
    if (!menuItemDetailsRef.current) {
      return "";
    }
    if (
      !selectedMenuItemsRef.current[menu_item.id] &&
      !!menuItemDetailsRef.current[menu_item.id]
    ) {
      selectedMenuItemsRef.current[menu_item.id] =
        menuItemDetailsRef.current[menu_item.id];
    }
    if (!selectedMenuItemsRef.current[menu_item.id]) {
      return "";
    }
    if (
      selectedMenuItemsRef.current[menu_item.id]["option"] ===
      available_option_value
    ) {
      return "lighten-5 white-text";
    } else {
      return "";
    }
  }

  const toggleComponentOptionValue = (menu_item, available_option_value) => {
    if (!selectedMenuItemsRef.current[menu_item.id]) {
      selectedMenuItemsRef.current[menu_item.id] =
        menuItemDetailsRef.current[menu_item.id];
    }
    if (
      selectedMenuItemsRef.current[menu_item.id]["option"] ===
      available_option_value
    ) {
      delete selectedMenuItemsRef.current[menu_item.id]["option"];
    } else {
      selectedMenuItemsRef.current[menu_item.id]["option"] = available_option_value;
    }

    getIncludedIngredients(menu_item, available_option_value);
    setSelectedRadio(available_option_value);
    decideButton(
      menu_item,
      selectedMenuItemsRef.current[menu_item.id]["option"],
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
      selectedMenuItemsRef.current[menu_item.id]["option"].option_value_id
      ]
    );
  }

  const getIngredientsForOption = (menu_item, chosen_option_value) => {

    if (!ingredientsForOption.current) {
      ingredientsForOption.current = {};
    }
    const ingredientsForOptionArray = [];
    let count = 0;

    const option_menu_item_ingredients =
      getSelectedMenuItemIngredients(menu_item);

    //create ingredient list for specific option also cater for whether the item is already selected.
    //if item is already selected and the maximum for it is one it should not appear in the list.
    for (let ingredient_list of menuItemDetailsRef.current[menu_item.id]
      .ingredient_lists) {
      if (!!ingredient_list.customizable_count > 0) {
        if (
          !chosen_option_value ||
          ingredient_list.option_value_id ===
          chosen_option_value.option_value_id
        ) {
          if (ingredient_list.menu_item_ingredients.length > 0) {
            let ingredient_count = 0;
            //clone menu_item_ingredients
            const menu_item_ingredients =
              ingredient_list.menu_item_ingredients.slice(0);
            for (let menu_item_ingredient of menu_item_ingredients) {
              ingredient_count = ingredient_count + 1;
              for (let selected_menu_item_ingredient_key of Object.keys(
                option_menu_item_ingredients
              )) {
                if (
                  menu_item_ingredient.id ===
                  option_menu_item_ingredients[
                    selected_menu_item_ingredient_key
                  ].id
                ) {
                  if (
                    !(
                      menu_item_ingredient.maximum >
                      option_menu_item_ingredients[
                      selected_menu_item_ingredient_key
                      ]["count"]
                    )
                  ) {
                    //reduce the index reference as object is getting removed
                    ingredient_count -= 1;
                    ingredient_list.menu_item_ingredients.splice(
                      ingredient_count,
                      1
                    );
                  }
                }
              }
            }

            ingredientsForOptionArray[count] = ingredient_list;
            count++;
          }
        }
      }
    }
    return ingredientsForOption.current[menu_item.id] =
      ingredientsForOptionArray;
  }

  const optionValueClass = (menu_item, available_option_value) => {
    if (!isLocationOnlineOrdersActive(fndLocation)) {
      return;
    }
    selectedMenuItemsRef.current[menu_item.id] =
      menuItemDetailsRef.current[menu_item.id];
    if (
      selectedMenuItemsRef.current[menu_item.id]["option"] ===
      available_option_value
    ) {
      return "grey lighten-5 white-text";
    } else {
      return "lighten-5 black-text";
    }
  }


  const getMenuItemDetails = (menu_item, callback) => {
    if (!menuItemDetailsRef.current) {
      menuItemDetailsRef.current = {};
    }
    if (
      !selectedMenuItemRef.current ||
      selectedMenuItemRef.current.id !== menu_item.id
    ) {
      if (!!menuItemDetailsRef.current[menu_item.id]) {
        //already downloaded and available
        selectedMenuItemRef.current = menuItemDetailsRef.current[menu_item.id];
        // showOptions.current = false;
        let component_option_values =
          menuItemDetailsRef.current[menu_item.id].component_option_values;
        if (!!component_option_values && component_option_values.length > 0) {
          for (let component_option_value of menuItemDetailsRef.current[
            menu_item.id
          ].component_option_values) {
            if (component_option_value.available_option_values.length > 1) {
              // showOptions.current = true;
            }
          }
        } else {
          // showOptions.current = false;
          //          @getIncludedIngredients(menu_item)
          preSelectOption(menu_item);
        }

        setLoading(false);
        if (!!callback) {
          callback();
        }
      } else {
        //not downloaded
        const MenuItem = MenuItemResource.get(Rails);
        
          MenuItem.get({ id: menu_item.id }, (menu_item) => {
            //@getIncludedIngredients(menu_item)
            selectedMenuItemRef.current = menu_item;
            menuItemDetailsRef.current[menu_item.id] = menu_item;
            preSelectOption(menu_item);
            // showOptions.current = false;
            if (
              menuItemDetailsRef.current[menu_item.id].component_option_values
                .length > 0
            ) {
              for (let component_option_value of menuItemDetailsRef.current[
                menu_item.id
              ].component_option_values) {
                if (component_option_value.available_option_values.length > 1) {
                  // showOptions.current = true;
                }
              }
            } else {
              // showOptions.current = false;
            }
            if (!!callback) {
              callback();
            }

            setLoading(false);
          });
      }
    } else {
      setLoading(false);
      if (!!callback) {
        callback();
      }
    }
  }

  const preSelectOption = (menu_item) => {
    let count = 0;
    const { component_option_values } = menuItemDetailsRef.current[menu_item.id];
    if (
      !!component_option_values &&
      Object.keys(component_option_values).length > 0
    ) {
      let selected_option;
      for (let key of Object.keys(component_option_values)) {
        for (let key1 of Object.keys(
          component_option_values[key].available_option_values
        )) {
          var least_price;
          count = count + 1;
          if (count === 1) {
            least_price =
              component_option_values[key].available_option_values[key1].price;
            selected_option =
              component_option_values[key].available_option_values[key1];
          } else {
            if (count > 1) {
              if (
                parseInt(least_price) >
                parseInt(
                  component_option_values[key].available_option_values[key1]
                    .price
                )
              ) {
                least_price =
                  component_option_values[key].available_option_values[key1]
                    .price;
                selected_option =
                  component_option_values[key].available_option_values[key1];
              }
            }
          }
        }
      }
      if (!!selected_option) {
        toggleComponentOptionValue(menu_item, selected_option);
      }
    } else {
      decideButton(menu_item);
    }
  }

  const getIncludedIngredients = (menu_item, chosen_option_value) => {

    let option_menu_item_ingredients, selected_option_value;
    selectedMenuItemsRef.current[menu_item.id] =
      menuItemDetailsRef.current[menu_item.id];

    if (!!selectedMenuItemsRef.current[menu_item.id]["option"]) {
      selected_option_value = selectedMenuItemsRef.current[menu_item.id]["option"];
    } else {
      selectedMenuItemsRef.current[menu_item.id]["option"] = chosen_option_value;
      selected_option_value = selectedMenuItemsRef.current[menu_item.id]["option"];
    }

    if (!selectedMenuItemsRef.current[menu_item.id]) {
      selectedMenuItemsRef.current[menu_item.id] = {};
    }
    if (!selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"]) {
      selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] = {};
    }

    if (selected_option_value) {
      option_menu_item_ingredients =
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
        selected_option_value.option_value_id
        ] ||
        (selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"][
          selected_option_value.option_value_id
        ] = {});
    } else {
      option_menu_item_ingredients =
        selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] ||
        (selectedMenuItemsRef.current[menu_item.id]["menu_item_ingredients"] = {});
    }

    if (!includedIngredients.current) {
      includedIngredients.current = {};
    }
    if (!includedIngredients.current[menu_item.id]) {
      includedIngredients.current[menu_item.id] = {};
    }
    //@includedIngredients[menu_item.id][chosen_option_value.id] || = {}
    const includedIngredientArray = [];

    if (Object.keys(option_menu_item_ingredients).length > 0) {
      const keys = Object.keys(option_menu_item_ingredients);
      for (let menu_item_ingredient_key of keys) {
        const id_of_ingredient_list =
          option_menu_item_ingredients[menu_item_ingredient_key]
            .ingredient_list_id;
        const name_of_menu_item_ingredient =
          option_menu_item_ingredients[menu_item_ingredient_key].ingredient
            .name;
        const count_of_menu_item_ingredient =
          option_menu_item_ingredients[menu_item_ingredient_key]["count"];
        for (let ingredient_list of menuItemDetailsRef.current[menu_item.id]
          .ingredient_lists) {
          if (!!ingredient_list.customizable_count > 0) {
            if (id_of_ingredient_list === ingredient_list.id) {
              const title_of_ingredient_list = ingredient_list.title;
              for (let find_ingredient_list of menuItemDetailsRef.current[
                menu_item.id
              ].ingredient_lists) {
                if (
                  title_of_ingredient_list === find_ingredient_list.title &&
                  (!chosen_option_value ||
                    chosen_option_value.option_value_id ===
                    find_ingredient_list.option_value_id) &&
                  id_of_ingredient_list !== find_ingredient_list.id
                ) {
                  for (let find_menu_item_ingredient of find_ingredient_list.menu_item_ingredients) {
                    if (
                      name_of_menu_item_ingredient ===
                      find_menu_item_ingredient.ingredient.name
                    ) {
                      delete option_menu_item_ingredients[
                        menu_item_ingredient_key
                      ];
                      option_menu_item_ingredients[
                        find_menu_item_ingredient.id
                      ] = find_menu_item_ingredient;
                      option_menu_item_ingredients[
                        find_menu_item_ingredient.id
                      ]["count"] = count_of_menu_item_ingredient;
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      let count = 0;
      for (let ingredient_list of menuItemDetailsRef.current[menu_item.id]
        .ingredient_lists) {
        if (!!ingredient_list.customizable_count > 0) {
          if (
            !chosen_option_value ||
            chosen_option_value.option_value_id ===
            ingredient_list.option_value_id
          ) {
            for (let menu_item_ingredient of ingredient_list.menu_item_ingredients) {
              if (!!menu_item_ingredient.customizable) {
                if (menu_item_ingredient.included > 0) {
                  if (!!includedIngredientArray) {
                    let match = 0;
                    for (let array_menu_item_ingredient of includedIngredientArray) {
                      if (
                        array_menu_item_ingredient.ingredient.name ===
                        menu_item_ingredient.ingredient.name
                      ) {
                        match = 1;
                      }
                    }
                    if (match === 0) {
                      includedIngredientArray[count] = menu_item_ingredient;
                      includedIngredientArray[count]["count"] =
                        menu_item_ingredient.included;
                      count = count + 1;
                    }
                  } else {
                    includedIngredientArray;
                    includedIngredientArray[count] = menu_item_ingredient;
                    includedIngredientArray[count]["count"] =
                      menu_item_ingredient.included;
                    count = count + 1;
                  }
                }
              }
            }
          }
        }
      }

      if (chosen_option_value) {
        includedIngredients.current[menu_item.id][
          chosen_option_value.option_value_id
        ] = includedIngredientArray;
      } else {
        includedIngredients.current[menu_item.id] = includedIngredientArray;
      }

      for (let menu_item_ingredient of includedIngredientArray) {
        option_menu_item_ingredients[menu_item_ingredient.id] =
          menu_item_ingredient;
        option_menu_item_ingredients[menu_item_ingredient.id]["count"] =
          menu_item_ingredient["count"];
      }
    }

    return getIngredientsForOption(menu_item, chosen_option_value);
  }

  const toggleColorForLikeable = (likeable, likeable_type) => {
    if (isLoggedIn(Rails)) {
      if (!user) {
        return "btn-blue";
      }

      const User = UserResource.get(Rails);
      if (isUserLiked(user, likeable, likeable_type)) {
        return "";
      } else {
        return "";
      }
    }
  }

  const showMenuItemTitle = (menu, menu_item) => {
    if (menu.show_position) {
      return `${ menu_item.order_position }. ${ menu_item.title }`;
    } else {
      return menu_item.title;
    }
  }

  const gotoMenu = (menu) => {
    window.location.hash = `menu_${ menu.id }`;
    // anchorScroll()
    setTimeout(() => {
      const diff = $(".content-change").height() - window.pageYOffset;
      if (diff > 200) {
        window.scrollBy(0, -200);
      }
    }, 100);
  }

  const MetaTags = getLocationMetaTags;
  return <>
    {fndLocation && <MetaTags location={fndLocation}/>}
    <LocationLayout 
      user={user}
      initUser={initUser}
      fndLocation={fndLocation}
      basket={basket}
      cartService={cartService}
      changeQuantity={changeQuantity}
      removeBasketItem={removeBasketItem}
      toggleLike={toggleLike}
      numberOfCartItems={numberOfCartItems}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      countryConfig={countryConfig}
      setLatLng={setLatLng}
      {...others}
    >
      <LocationMenu 
        Rails={Rails}
        onSubmitReview={onSubmitReview}
        review_title={review_title}
        user={user}
        fndLocation={fndLocation}
        openReviewModal={openReviewModal}
        openMenuItemModal={openMenuItemModal}
        showReviewDialog={showReviewDialog}
        setShowReviewDialog={setShowReviewDialog}
        closeReviewDialog={closeReviewDialog}
        toggleLike={toggleLike}
        reviews={review}      
        class_for_option_value={class_for_option_value}
        addToBasketDirect={addToBasketDirect}
        {...others} 
      />
    </LocationLayout>
    <MenuItemModals 
      user={user}
      addToCartDialog={addToCartDialog}
      closeMenuItemModal={closeMenuItemModal}
      menu={menu}
      menu_item={menuItem}
      menu_item_details={menuItemDetailsRef.current}
      selectedDialogItem={selectedDialogItem}
      selectedRadio={selectedRadio}
      toggleComponentOptionValue={toggleComponentOptionValue}
      fndLocation={fndLocation}
      ingredientsForOption={ingredientsForOption}
      addMenuItemIngredient={addMenuItemIngredient}
      selected_menu_item_ingredients={selectedMenuItemIngredients}
      selected_ingredients={selectedIngredientsRef.current}
      removeMenuItemIngredient={removeMenuItemIngredient}
      removeFromBasket={removeFromBasket}
      addToBasket={addToBasket}
      existsInBasket={existsInBasket}
      error_message={error_message}
      {...others} 
    />
  </>;
};

export default withParent(AppLocation);
