import React from "react";
import { Link } from "react-router";
import { I18nRails } from "../../shared/rails-i18n-js";
import ParentComponent from "./ParentComponent";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { getLocationDistance, location_address, location_card_logo_url, location_main_image_url } from "../models/location";

const LocationCard = props => {
  const {
    latLng,
    fndLocation
  } = props;

  function getDistance() {
    if (!!props.latLng) {
      return getLocationDistance(fndLocation, latLng);
    }
    // if !!@state.user && fdLocations.length > 0
    //   if !!@state.user.provider
    //     fdLocation_ids = fdLocations.map (l) => l.id
    //     me = @
    //     @FavoriteService.fdLocations_friends_favorites(fdLocation_ids.join(',')).then (friends_favorites) =>
    //       me.user.setFriendsFavorites(friends_favorites.data)
  }

  function getLatestSchedule(schedules) {
    var maxID = Math.max.apply(
      Math,
      schedules.map(function(schedule) {
        return schedule.id;
      })
    );
    var latestSchedule = schedules.find((schedule) => schedule.id == maxID);

    return latestSchedule.text != null ? latestSchedule.text : "";
  }

  return (
    (<Link to={'/locations/' + fndLocation.slug}>
      <>
        {fndLocation.schedules &&
          fndLocation.schedules.length > 0 &&
          getLatestSchedule(fndLocation.schedules) != "" ? (
          <div className="box">
            <div id="ribbon-top-right" className="ribbon ribbon-top-right">
              <span>{getLatestSchedule(fndLocation.schedules)}</span>
            </div>
          </div>
        ) : null}
        <Card id="locationCard" elevation={4} style={{ height: 370 }}>
          <CardMedia>
            <img
              style={{ height: "215px", width: "100%", objectFit: "cover" }}
              src={location_main_image_url(fndLocation)}
            />
          </CardMedia>

          <CardHeader
            avatar={
              <Avatar alt="Cindy Baker" src={location_card_logo_url(fndLocation)} />
            }
            action={
              <>
                <Tooltip title="Verified Location">
                  <IconButton style={{ cursor: "default" }}>
                    <VerifiedUserIcon style={{ color: "#65d865" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Favorite">
                  <IconButton
                    disableFocusRipple
                    style={{
                      paddingRight: 0,
                      "&:hover": { backgroundColor: "#FFF" },
                    }}
                    onClick={(e) =>
                      props.inFavoriteRoute
                        ? props.toggleFavoriteLike(
                          fndLocation,
                          "Location",
                          e
                        )
                        : props.toggleLike(fndLocation, "Location", e)
                    }
                    aria-label="settings"
                  >
                    <FavoriteIcon
                      style={{
                        color:
                          (props.user &&
                            props.isLiked(fndLocation, "Location").red) ||
                            fndLocation.red
                            ? "red"
                            : "grey",
                      }}
                    />
                    <span style={{ fontSize: 14 }}>
                      {fndLocation.favorites_count}
                    </span>
                  </IconButton>
                </Tooltip>
              </>
            }
            subheader={fndLocation.name}
          >
            <img
              style={{ height: "100px", width: "100px" }}
              src={location_main_image_url(fndLocation)}
            />
          </CardHeader>

          <CardContent>
            <Grid container>
              <Grid size={12}>
                {!!fndLocation.addresses && fndLocation.addresses.length > 0 ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {location_address(fndLocation).local_address}
                  </Typography>
                ) : null}
              </Grid>
              <Grid size={3}>
                {fndLocation.deliveries && fndLocation.deliveries.length > 0 ? (
                  <Chip label="Delivery" icon={<DoneIcon />} />
                ) : null}
              </Grid>
              <Grid size={1}></Grid>
              <Grid size={3}>
                {fndLocation.online_booking == "active" ? (
                  <Chip
                    label="Online"
                    style={{ marginleft: 15 }}
                    icon={<DoneIcon />}
                  />
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    </Link>)
  );
};

export default LocationCard;

