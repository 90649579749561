import React, { useEffect } from "react";
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter, createBrowserRouter, RouterProvider } from "react-router";
import { useRoutes } from "react-router";

import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";


import { CacheProvider } from '@emotion/react';
//import createEmotionServer from '@emotion/server/create-instance';
import createEmotionCache from './createEmotionCache';


import { locationRoutes, siteRoutes } from "../ngreact/routes";
import { SsrPropsProvider } from "./SsrContext";

// import { convertCustomRouteConfig, ensureReady } from "../ngreact/rrv4Helpers";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00a65a",
    },
  },
});
const cache = createEmotionCache();

if (typeof window !== "undefined") {
  const props = JSON.parse(document.getElementById("props").dataset.props);

  const routes = !!props.Rails.domain_place_id && props.Rails.domain_place_type === "Location" ? locationRoutes : siteRoutes; 
  const router = createBrowserRouter(routes, {
    future: {
      v7_relativeSplatPath: true,
      v7_startTransition: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    }      
  })

  const Main = () => {
    useEffect(() => {
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }, []);


    return (
      <CacheProvider value={cache}>
        <SsrPropsProvider props={props}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </SsrPropsProvider>
      </CacheProvider>
    );
  }
  if (document.getElementsByTagName("react-view")[0].innerHTML) {
    const root = hydrateRoot(document.getElementsByTagName("react-view")[0], <Main />);
  } else {
    const root = createRoot(document.getElementsByTagName("react-view")[0]); // createRoot(container!) if you use TypeScript
    root.render(<Main />);
  }
} else {
  // renderSSR(
  //   serverProps.location_fullpath,
  //   serverProps.location_pathname,
  //   serverProps
  // );
}
