import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import OfferResource from "../../resources/offer";
import LocationResource from "../../resources/location";
import MenuItemReviewResource from "../../resources/menu-item-review";
import MenuReviewResource from "../../resources/menu-review";
import LocationReviewResource from "../../resources/location-review";
import CartService from "../../services/CartService";
import UserResource from "../../resources/user";
import { SUPPORTED_CITIES } from "../../constants";
import queryString from "query-string";
import isMobile from "ismobilejs";
import GeolocationFactory from "../../services/GeolocationFactory";
import { resetBasketTotals } from "../Model/basket";
import { I18nRails } from "../../../shared/rails-i18n-js";
import AuthFactory from "../../services/AuthFactory";
import { getLocationId, isLoggedIn } from "../helpers/utils";
// import update from 'immutability-helper';
import sweetalert2 from "sweetalert2";
import { useSsrProps } from "../../SsrContext";

//this is an abstract class which is always extended by a child class which has other things
const withParent =
  (Component) =>
  ({
    basket: propBasket,
    user: propUser,
    domain_place_type,
  }) => {
    const ssrProps = useSsrProps()
    const {Rails = {}, fdLocation, fdUser, fdOffers} = ssrProps;

    const params = useParams();
    const domLocation = useLocation();
    const [locationId, setLocationId] = useState(getLocationId(Rails, params));
    const [location, setLocation] = useState(fdLocation ? JSON.parse(fdLocation) : "");
    const [infiniteLocations, setInfiniteLocations] = useState({});
    const [reviews, setReviews] = useState([]);
    const [user, setUser] = useState(fdUser ? JSON.parse(fdUser) : "");
    const [offers, setOffers] = useState([]);
    const [latLng, setLatLng] = useState({});
    const [selectedCountry, setSelectedCountry] = useState(Rails.current_country);
    const [countryConfig, setCountryConfig] = useState(Rails[Rails.locale_country]);

    const userCallbacksRef = useRef([]);
    const locationCallbacksRef = useRef([]);
    const cartServiceRef = useRef(CartService.get(Rails));

    const initBasket = () => {
      let basket;
      if (
        !!propBasket &&
        !!params &&
        (!params.location_id || propBasket.location_id === params.location_id)
      ) {
        basket = {...propBasket}
      } else if (!!locationId) {
        basket = cartServiceRef.current.get(locationId);
      } else {
        return null;
      }
      basket.totals.numberOfCartItems =
        cartServiceRef.current.numberOfItems(basket.location_id) || "";
      resetBasketTotals(basket, cartServiceRef.current);
      if(location && basket.location_id == location.slug) {
        basket.location = location
      } 
      return basket;
    };

    const [basket, setBasket] = useState(initBasket());


    const ajaxHeaders = () => {
      const headers = {
        "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (!!Rails.access_token) {
        headers["Authorization"] = `Bearer ${Rails.access_token}`;
      }
      return headers;
    };

    const loadUser = (callback) => {
      const userCallbacks = userCallbacksRef.current;
      userCallbacks << callback;
      if (userCallbacks.length > 1) {
        return;
      }

      const User = UserResource.get(Rails);
      User.get(
        { id: "me" },
        (user) => {
          setUser(user);
          userCallbacks.forEach((callback) => callback(location));
          userCallbacksRef.current = [];
          if (!!callback) {
            callback(user);
          }
        },
        (error) => console.error(error)
      );
    };

    const loadLocation = (callback) => {
      const locationCallbacks = locationCallbacksRef.current;
      locationCallbacks.push(callback);
      if (locationCallbacks.length > 1) {
        return;
      }
      if (!fdLocation) {
        const Location = LocationResource.get(Rails)
        Location.get({ id: locationId }, (location) => {
          setLocation(location);
          setBasket({...basket, location});
          locationCallbacks.forEach((callback) => callback(location));
          locationCallbacksRef.current = [];
        });
      } else {
        const location = JSON.parse(fdLocation);
        if (location.slug === locationId) {
          setLocation(location);
          setBasket({...basket, location});
          locationCallbacks.forEach((callback) => callback(location));
          locationCallbacksRef.current = [];
        } else {
          const Location = LocationResource.get(Rails)
          Location.get({ id: locationId }, (location) => {
            setLocation(location);
            setBasket({...basket, location});
            locationCallbacks.forEach((callback) => callback(location));
            locationCallbacksRef.current = [];
          });  
        }
      }
    };

    const initLocation = (callback) => {
      if(!location || location.slug !== locationId) {
        loadLocation(callback)
      } else {
        if(callback) callback(location)
      }
    }

    const initUser = (callback) => {
      let userObject;
      if (!!fdUser) {
        userObject = JSON.parse(fdUser);
        if (!!callback) {
          callback(userObject);
        }
        return userObject;
      } else if (!!propUser) {
        userObject = propUser;
        if (!!callback) {
          callback(userObject);
        }
        return userObject;
      } else if (user) {
        userObject = user;
        if (!!callback) {
          callback(userObject);
        }
        return userObject;
      } else if (isLoggedIn(Rails)) {
        loadUser(callback);
        return null;
      }
    };

    const setBasketField = (key, e) => {
      setBasket({ ...basket, [key]: e.target.value });
    };

    const getDefaultSearchCity = () => {
      const query_params = queryString.parse(domLocation.search);
      if (!!query_params.city) {
        return query_params.city;
      }
      if (
        !!Rails.current_city &&
        SUPPORTED_CITIES.includes(Rails.current_city)
      ) {
        return Rails.current_city;
      } else {
        return "";
      }
    };

    const errorClass = (form, name) => {
      const s = form[name];
      if (!form[name]) {
        return "";
      }
      if (s.$invalid && s.$dirty) {
        return "wrong";
      } else {
        return "right";
      }
    };

    const errorMessage = (form, name) => {
      const result = [];
      if (!form[name] || form[name].$valid) {
        return;
      }
      for (let key in form[name].$error) {
        const value = form[name].$error[key];
        if (!!key) {
          if (name !== key) {
            result.push(`${name} ${key}`);
          } else {
            result.push(`${name} is invalid`);
          }
        }
      }

      return result.join(", ");
    };

    const notifyNative = (event, params) => {
      if (typeof window === "undefined") {
        return;
      }
      if (isMobile(window.navigator.userAgent).any && !!window) {
        if (!!window.foodnerdApi) {
          window.foodnerdApi.postMessage(event, params.message);
          true;
        } else if (
          !!window.webkit &&
          !!window.webkit.messageHandlers.foodnerdApi
        ) {
          window.webkit.messageHandlers.foodnerdApi.postMessage({
            event: params["data-event"],
            error: params["data-error"],
            warn: params["data-warn"],
            notice: params["data-notice"],
            message: params["data-message"],
            location: params["data-location"],
          });
          return true;
        } else if (!!window.originalPostMessage) {
          window.postMessage(event);
          return true;
        } else {
          setTimeout(function () {
            window.postMessage(event, event.origin);
            return true;
          }, 200);
        }
        return false;
      } else {
        return false;
      }
    };

    const initOffers = () => {
      if (!fdOffers) {
        fetchOffers();
      } else {
        const offers = JSON.parse(fdOffers);
        setOffers(offers);
      }
    };

    const fetchOffers = () => {
      const params = {};
      params.country_code = Rails.locale_country;

      const Offer = OfferResource.get(Rails);
      Offer.query(params, (offers) => {
        setOffers(offers);
      });
    };

    const geolocateUser = (callback) => {
      const Geolocation = new GeolocationFactory(Rails);
      if (!!latLng) {
        if (!!callback) {
          callback(latLng);
        }
        return;
      }
      Geolocation.get((latLng) => {
        setLatLng(latLng);
        if (!!callback) {
          callback(latLng);
        }
      });
    };

    const isRequiredPhoneVerification = () => {
      return !!Rails.require_phone_verification;
    };

    const anyOfferHasBanner = (offers) => {
      for (let offer of offers) {
        if (!!offer.main_image_url && !!offer.show_main) {
          return true;
        }
      }
      return false;
    };

    const isLocationSite = () => {
      return domain_place_type === "Location";
    };

    const isChainSite = () => {
      return Rails.domain_place_type === "Chain";
    };

    const toggleLike = (likeable, likeable_type, event) => {
      event.preventDefault();
      event.stopPropagation();
      const User = UserResource.get(Rails);
      let userObject;
      if (!user) {
        userObject = initUser();
        userObject = new User(userObject);
      } else {
        userObject = new User(user);
      }

      if (!isLoggedIn(Rails)) {
        const success = I18nRails.t("venue.alertmessage.togglelikealert");
        sweetalert2.fire(`${success}`);
      } else {
        if (likeable_type == "Location") {
          if (!infiniteLocations) {
            if (userObject.is_liked(likeable, likeable_type)) {
              likeable.red = false;
              likeable.favorites_count--;
              userObject.unlike(likeable, likeable_type);
            } else {
              likeable.red = true;
              likeable.favorites_count++;
              userObject.like(likeable, likeable_type);
            }
            return;
          } else {
            const index = infiniteLocations.items.findIndex(
              (item) => item[0].id === likeable.id
            );
            var found = infiniteLocations.items.find(
              (item) => item[0].id == likeable.id
            );
            if (userObject.is_liked(likeable, likeable_type)) {
              found[0].red = false;
              found[0].favorites_count--;
              userObject.unlike(found[0], likeable_type);
            } else {
              found[0].red = true;
              found[0].favorites_count++;
              userObject.like(found[0], likeable_type);
            }
          }
        } else {
          if (userObject.is_liked(likeable, likeable_type)) {
            userObject.unlike(likeable, likeable_type);
          } else {
            userObject.like(likeable, likeable_type);
          }
        }
        setInfiniteLocations({
          ...infiniteLocations,
          items: infiniteLocations.items,
        });
      }
    };
    const isLiked = (likeable, likeable_type, event) => {
      let item = likeable;
      const User = UserResource.get(Rails);
      let userObject = new User(user);
      if (userObject.is_liked(likeable, likeable_type)) {
        item.red = true;
      } else {
        item.red = false;
      }
      return item;
    };

    const logout = () => {
      localStorage.removeItem("baskets");
      const AuthService = new AuthFactory(Rails);
      AuthService.logout().then(() => {
        if (!notifyNative("onSignOut")) {
          window.location.href = "/";
        }
      });
    };

    const clearCart = (basket) => {
      cartServiceRef.current.clearCart(basket.location_id);
      const updated_basket = cartServiceRef.current.get(basket.location_id);
      updated_basket.voucher = null;
      updated_basket.voucher_code = null;
      resetBasketTotals(updated_basket, cartServiceRef.current);
      setBasket(updated_basket);
    };

    const getUpdatedBasket = () => {
      initBasket();
    };
    
    const onSubmitReview = (values) => {
      // TODO: need to move this function to AppLocation.js
      let reviewee_type = ""; // revieweeType;
      let reviewee_id = ""; // reviewee.id;
      // e.preventDefault();
      if (!isLoggedIn(Rails)) {
        const message = I18nRails.t("venue.alertmessage.togglereviewalert");
        sweetalert2.fire(message);
      } else {
        if (!!values.reviewContent) {
          let review;
          if (reviewee_type == "Menu") {
            const MenuReview = MenuReviewResource.get(Rails);
            review = new MenuReview({
              menu_id: reviewee_id,
              review: { content: values.reviewContent },
            });
          } else if (reviewee_type == "MenuItem") {
            const MenuItemReview = MenuItemReviewResource.get(Rails);
            review = new MenuItemReview({
              menu_item_id: reviewee_id,
              review: { content: values.reviewContent },
            });
          } else if (reviewee_type == "Location") {
            const LocationReview = LocationReviewResource.get(Rails);
            review = new LocationReview({
              location_id: reviewee_id,
              review: { content: values.reviewContent },
            });
          } else {
            sweetalert2.fire("Invalid reviewee type");
          }
          review.$create(
            (response) => {
              setReviews([...reviews, response]);
              // TODO: need to move this function to AppLocation.js
              // setShowReviewDialog(false);
              sweetalert2.fire("Your feedback is added");
              //TODO: add review count
            },
            (error) => {
              showResourceError(error);
            }
          );
        }
      }
    };

    const openReviewModal = (reviewee_type, reviewee, e) => {
      // TODO: need to move this to AppLocation.js as well
      if (reviewee_type === "Menu") {
        setReviewTitle(`Opinions about ${reviewee.title}`);
        MenuReview.query({ menu_id: reviewee.slug }, (menu_reviews) => {
          setReviews(menu_reviews);
          // setRevieweeType(reviewee_type);
          // setReviewee(reviewee);
          // setShowReviewDialog(true);
        });
      } else if (reviewee_type === "MenuItem") {
        setReviewTitle(`Feedback About ${reviewee.title}`);
        MenuItemReview.query(
          { menu_item_id: reviewee.slug },
          (menu_item_reviews) => {
            setReviews(menu_item_reviews);
            // setRevieweeType(reviewee_type);
            // setReviewee(reviewee);
            // setShowReviewDialog(true);
          }
        );
      } else if (reviewee_type === "Location") {
        setReviewTitle(`Feedback About ${reviewee.title}`);
        const LocationReview = LocationReviewResource.get(Rails);
        LocationReview.query(
          { location_id: reviewee.slug },
          (location_reviews) => {
            setReviews(location_reviews);
            // setRevieweeType(reviewee_type);
            // setReviewee(reviewee);
            // setShowReviewDialog(true);
          }
        );
      }
    };

    const showResourceResponseError = (response) => {
      if (!!response.errors) {
        sweetalert2.fire(response.errors.join("\n"));
      } else if (!!response.full_messages) {
        sweetalert2.fire(response.full_messages);
      } else {
        const errors = [];
        for (let key in response) {
          const field_errors = response[key];
          for (let field_error of field_errors) {
            errors.push(`${key} ${field_error}`);
          }
        }
        sweetalert2.fire(errors.join("\n"));
      }
    };

    const showResourceError = (error) => {
      if (!!error.response) {
        error.response.json().then((response) => {
          showResourceResponseError(response);
        });
      } else {
        sweetalert2.fire(error.message);
      }
    };

    const removeBasketItem = (item) => {
      cartServiceRef.current.removeItem(
        item.menu_item,
        item.component_option_value,
        item.menu_item.ingredient_lists,
        locationId
      );
      const items = cartServiceRef.current.get(locationId).items;
      setBasket({ ...basket, items });
    };
    const changeQuantity = (item, addOrRemove) => {
      if (addOrRemove == "add") {
        cartServiceRef.current.increaseQuantity(locationId, item);
      } else if (addOrRemove == "remove") {
        cartServiceRef.current.decreaseQuantity(locationId, item);
      }
      const items = cartServiceRef.current.get(locationId).items;
      setBasket({ ...basket, items });
    };

    const changeCountry = (current_country_alpha) => {
      let current_country = current_country_alpha;
      if (!!Rails[current_country_alpha]) {
        countryConfig = Rails[current_country_alpha];
        ({ locale } = countryConfig);
      } else {
        current_country = null;
        locale = I18nRails.defaultLocale;
      }
      I18nRails.setI18nLocale(locale);
      setSelectedCountry(current_country_alpha);
      setLocale(locale);
      setCountryConfig(countryConfig);
    };
  
    return (
      <Component
        locationId={locationId}
        setLocationId={setLocationId}
        fndLocation={location}
        setLocation={setLocation}
        countryConfig={countryConfig}
        selectedCountry={selectedCountry}
        changeCountry={changeCountry}
        infiniteLocations={infiniteLocations}
        setInfiniteLocations={setInfiniteLocations}
        reviews={reviews}
        setReviews={setReviews}
        user={user}
        setUser={setUser}
        basket={basket}
        setBasket={setBasket}
        offers={offers}
        setOffers={setOffers}
        latLng={latLng}
        setLatLng={setLatLng}
        ajaxHeaders={ajaxHeaders}
        loadUser={loadUser}
        loadLocation={loadLocation}
        initLocation={initLocation}
        initBasket={initBasket}
        initUser={initUser}
        setBasketField={setBasketField}
        errorClass={errorClass}
        errorMessage={errorMessage}
        notifyNative={notifyNative}
        initOffers={initOffers}
        fetchOffers={fetchOffers}
        geolocateUser={geolocateUser}
        isRequiredPhoneVerification={isRequiredPhoneVerification}
        anyOfferHasBanner={anyOfferHasBanner}
        isLocationSite={isLocationSite}
        isChainSite={isChainSite}
        toggleLike={toggleLike}
        isLiked={isLiked}
        logout={logout}
        clearCart={clearCart}
        showResourceResponseError={showResourceResponseError}
        showResourceError={showResourceError}
        removeBasketItem={removeBasketItem}
        changeQuantity={changeQuantity}
        getUpdatedBasket={getUpdatedBasket}
        getDefaultSearchCity={getDefaultSearchCity}
        cartService={cartServiceRef.current}
        Rails={Rails}
        propBasket={propBasket}
        fdOffers={fdOffers}
        domain_place_type={domain_place_type}
      />
    );
  };

export default withParent;
