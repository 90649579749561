import React, { useState, useRef } from 'react';

import { Link, useNavigate, useParams } from "react-router";

import { Formik, Form, Field, ErrorMessage } from 'formik';

import AppLayout from './AppLayout';
import PasswordFactory from '../resources/password';
import * as rh from './helpers/routes';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { loadImage } from './helpers/utils';
import withParent from './hoc/withParent';

const AppUpdatePassword = ({
  Rails,
  user,
  showResourceError
}) => {
  const [values, setValues] = useState({
    password: '',
    password_confirmation: ''
  });

  const [error, setError] = useState();

  const navigate = useNavigate();
  const params = useParams()
  const onSubmit = (values, options) => {

    values.reset_password_token = params.token;
    const ResetPassword = PasswordFactory.get(Rails);

    ResetPassword.update({ user: values }, success => {
      options.setSubmitting(false);
      return navigate('/login', { reset: true });
    }
      ,
      error => {
        options.setSubmitting(false);
        showResourceError(error);
      });
  };

  const validateHandler = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = ['password required'];
    } else if (values.password.length < 6) {
      errors.password = ['password should be more tan 6 characters'];
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = ['please confirm the password'];
    } else if (values.password_confirmation) {
      if (values.password_confirmation !== values.password) {
        errors.password_confirmation = (errors.password = ['No Match']);
      }
    }

    return errors;
  };

  const children = ({ values, errors, touched, isSubmitting, isValidating }) => {
    return (
      (<Form method='post' className='form-signin login-form'>
        <Grid container justify='center' alignItems='center'>
          <Grid size={4}>
            <h3 style={{textAlign:'center', marginTop:'10%'}} className='white-text center'>Reset your password</h3>
            <Card style={{height:'350px',  marginBottom:'20%', paddingTop:'4%'}}>
              <CardContent>
                <Grid container justify='center' alignItems='center'>
                  <Grid size={10}>
                    <Field type='password' name='password' required minLength={6} className='browser-default form-control password' placeholder='Password'/>
                    <ErrorMessage name='password' component='validation-message'/>
                    <Field type='password' name='password_confirmation' placeholder='Confirm Password' required minLength={6} className='browser-default form-control password_confirmation'/>
                    <ErrorMessage name='password_confirmation' component='validation-message'/>
                    {!!error && <span>{error}</span>}
                  </Grid>
                  <Grid size={5}>
                    <div className='input-field login-btn'>
                      <Button style={{marginTop:'20%'}} type='submit' className='btn-red' disabled={isSubmitting}>Change Password</Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container justify='center' alignItems='center'>
              <Grid size={5}>
                <div className='input-field'>
                  <p style={{textAlign:'center', paddingTop:'10%'}} className='margin medium-small no-mar-top'>
                    <Link to={rh.login()}>Back To Login</Link>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>)
    );
  }

  return (
    (<AppLayout Rails={Rails} user={user} {...others}>
      <Grid container>
        <Grid style={{ paddingTop: '6%' }} size={12}>
          <section style={{ backgroundImage: 'url(' + loadImage("image.jpg") + ')' }}>
            <Formik
              className="form-signin mg-btm login-form"
              initialValues={values}
              validate={validateHandler}
              onSubmit={onSubmit}
              children={children}
            />
          </section>
        </Grid>
      </Grid>
    </AppLayout>)
  );
};

export default withParent(AppUpdatePassword);

