import React, { useState, useRef, useEffect } from "react";

import LocationLayout from "./LocationLayout";
import Card from "@mui/material/Card";
import { I18nRails } from "../../shared/rails-i18n-js";
import { location_address } from "../models/location";
import withParent from "./hoc/withParent";


const AppLocationAbout = ({
  Rails,
  initLocation,
  setLatLng,
  basket,
  user,
  cartService,
  changeQuantity,
  initUser,
  fndLocation,
  removeBasketItem,
  toggleLike,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  ...other
}) => {
  useEffect(() => {
    initLocation();
  })
  return (
    <LocationLayout 
      user={user}
      initUser={initUser}
      fndLocation={fndLocation}
      basket={basket}
      cartService={cartService}
      changeQuantity={changeQuantity}
      removeBasketItem={removeBasketItem}
      toggleLike={toggleLike}
      numberOfCartItems={numberOfCartItems}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      countryConfig={countryConfig}
      setLatLng={setLatLng}
      {...other}
    >
      {!!location && (
        <Card elevation={4} style={{padding: '3%'}}>
          <p className="heading-font">{I18nRails.t("Web.about_us")}</p>
          <p>{location.description}</p>
          <div className="divider"></div>
          <p className="subhead-font2">{I18nRails.t("Web.contact_info")}</p>
          <p>
            {I18nRails.t("Web.phone")}{" "}
            <a href={`tel:${location.phone}`}>{location.phone}</a>
            <br />
            {I18nRails.t("Web.address")}: {location_address(location).full_address}
          </p>
        </Card>
      )}
    </LocationLayout>
  );
};

export default withParent(AppLocationAbout);