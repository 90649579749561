import React from 'react';

import MenuItemCard from './MenuItemCard';

import MenuItemResource from '../resources/menu-item';

import * as rh from './helpers/routes';

import { I18nRails } from '../../shared/rails-i18n-js';
import Grid from '@mui/material/Grid2';


const FavoriteMenuItems = props => {
  console.log('this.props.favorites', props.favorites);
  return (
    (<Grid container spacing={4} style={{padding:'2%',width:'100%'}}>
      {props.favorites.map((favorite) => (
        <Grid key={`mi+hom${favorite.id}`} size={3}>
          <MenuItemCard menu_item={favorite} />
        </Grid>
      ))}
    </Grid>)
  );
};

export default FavoriteMenuItems;
