import React, { useState, useRef, useEffect } from 'react';
import isMobile from 'ismobilejs';
import StickyCart from './StickyCart';
import AppHeader from './Headers/AppHeader';
import AppSideNav from './AppSideNav';
import AppFooter from './Footers/AppFooter';
import Header from './templates/_header';
import SubHeader from './templates/SubHeader';
import LocationTabs from './templates/_location-tabs';
import Maps from './templates/_maps';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import { I18nRails } from '../../shared/rails-i18n-js';
import { useSsrProps } from '../SsrContext';
import { useSearchParams } from 'react-router';

const LocationLayout = ({
  user,
  initUser,
  fndLocation,
  basket,
  cartService,
  changeQuantity,
  removeBasketItem,
  toggleLike,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  setLatLng,
  children,
  ...others
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;
  const [searchParams, setSearchParams] = useSearchParams();

  if(!fndLocation) debugger;
  const [isSideNavOpened, setIsSideNavOpened] = useState(false);
  const [viewHours, setViewHours] = useState(false);

  const handleAppSideNav = () => {
    setIsSideNavOpened(!isSideNavOpened);
  };

  const openHours = () => {
    setViewHours(true);
  };

  const closeHours = () => {
    console.log('close hours');
    setViewHours(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const isMobileBrowser = (typeof window !== 'undefined') && isMobile(window.navigator.userAgent).any
  const isMenuMode = searchParams.get('menu_mode');
  if(!fndLocation) debugger;
  return (<>
    {(!isMenuMode && 
      <>
      <AppHeader 
        fndLocation={fndLocation}
        countryConfig={countryConfig}
        basket={basket}
        user={user}
        handleAppSideNav={handleAppSideNav}
        numberOfCartItems={numberOfCartItems}
      />
      <AppSideNav 
        Rails={Rails}
        handleAppSideNav={handleAppSideNav} 
        isSideNavOpened={isSideNavOpened}
        countryConfig={countryConfig}
        selectedCountry={selectedCountry}
        changeCountry={changeCountry}

        {...others} 
      />

      </>
    )}
    {fndLocation ? (
      <Grid container style={{paddingBottom:'5%'}}>
        {isMobileBrowser ? (
          <>
            <Grid size={12}>
              <Header 
                fndLocation={fndLocation}
                openHours={openHours}
                isMenuMode={isMenuMode}
              />
              <SubHeader
                Rails={Rails}
                user={user}
                initUser={initUser}
                fndLocation={fndLocation}
                toggleLike={toggleLike}        
              />
              { children }
            </Grid>
            {/* <Grid size={12}>
              <StickyCart 
                className='sticky-cart' 
                Rails={Rails} 
                cartService={cartService}
                basket={basket}
                changeQuantity={changeQuantity}
                removeBasketItem={removeBasketItem}                
              />
            </Grid> */}
          </>
        ) : (
          <>
            <Grid size={12}>
              <Header 
                fndLocation={fndLocation}
                openHours={openHours}
                isMenuMode={isMenuMode}
              />
              <SubHeader
                Rails={Rails}
                user={user}
                initUser={initUser}
                fndLocation={fndLocation}
                toggleLike={toggleLike}
              />
            </Grid>
            <Grid
              style={{backgroundColor:'#F7F7F7',paddingLeft:'3%', paddingRight:'3%'}}
              size={8}>
              <LocationTabs Rails={Rails} fndLocation={fndLocation}/>
              { children }
            </Grid>
            <Grid size={4}>
              <StickyCart 
                className='sticky-cart' 
                Rails={Rails} {...others}
                cartService={cartService}
                basket={basket}
                changeQuantity={changeQuantity}
                removeBasketItem={removeBasketItem}                
              />
            </Grid>
          </>
        )}
        <Maps
          fndLocation={fndLocation}
          viewHours={viewHours}
          closeHours={closeHours}
          setLatLng={setLatLng}
          unmappedAddress={null}      
        />            
      </Grid>
    ) : (
      <>
        <CircularProgress style={{marginTop:'8%', marginLeft:'2%'}}/>
        <p style={{marginLeft:'2%'}}>
          { I18nRails.t('Web.loading') }
        </p>
      </>
    )}
    <AppFooter 
      Rails={Rails}
      fndLocation={fndLocation}
      basket={basket}
      numberOfCartItems={numberOfCartItems}
      countryConfig={countryConfig}              
      {...others} 
    />
  </>);
};

export default LocationLayout;
